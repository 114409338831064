import React, { useState, useEffect } from 'react';

// Redux stuff
import { getCouriers } from '../../redux/actions';
import { useSelector, useDispatch } from 'react-redux';

import { Link, useRouteMatch, useHistory } from 'react-router-dom';

//components
import Loading from '../../components/Loading';

// pages

// MUI Stuff
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Grid, Button, Typography, Chip } from '@mui/material';

import { getCourierImage } from '../../util/functions'




const styles = {
    container: {
        width: '100%',
        height: '100%'
    },
    textField: {
        margin: '10px auto 10px auto'
    },
    button: {
        marginTop: 20,
        position: 'relative'
    },
    addProviderBtn: {
        marginRight: 20
    },
    progress: {
        position: 'absolute',
        color: '#ffcc00'
    },
    customError: {
        color: 'red',
        fontSize: '0.8rem',
        marginTop: 10,
    },
    addCourierBtn: {
        //?margin: theme.spacing(1),
    },
};

const columns = [
    {
        field: 'image',
        headerName: 'Profil',
        //width: 80,
        flex: 0.5,
        height: window.innerWidth / 12,
        renderCell: (params) => (
            <img src={getCourierImage(params.getValue(params.id, 'id') || '')} alt={params.value} style={{ maxWidth: "80px", height: "100%", padding: "0.15em", borderRadius: "50%" }} />
        )
    },
    {
        field: 'name',
        headerName: 'İsim',
        flex: 1,
        //width: "25%",
    },
    {
        field: 'last_name',
        headerName: 'Soyisim',
        flex: 1,
        //width: "25%",
    },
    {
        field: 'county', headerName: 'İlçe', flex: 1,
        valueFormatter: (params) => {
            return params.value.name
        },
    },
    {
        field: 'city', headerName: 'Şehir', flex: 1,
        valueFormatter: (params) => {
            return params.value.name
        },
    },
    {
        field: 'status', headerName: 'Statü', flex: 2, renderCell: (params) => (
            <div>
                <Chip
                    style={{ color: "#fff" }}
                    color={params.value == -1 ? "error" : params.value == 0 ? "warning" : params.value == 1 ? "success" : null}
                    label={params.value == -1 ? "İlişiği Kesildi" : params.value == 0 ? "İnaktif" : params.value == 1 ? "Aktif" : null}>

                </Chip>
            </div>
        )
    }
];


const Courier = () => {
    let history = useHistory();
    const couriers = useSelector(state => state.admin.couriers);

    const [currentPage, setCurrentPage] = useState(null)

    const dispatch = useDispatch()

    useEffect(() => {
        setCurrentPage(1)
    }, [])

    useEffect(() => {
        if (currentPage) {
            dispatch(getCouriers(currentPage));
        }
    }, [currentPage])


    const handlePageChange = (page, details) => {
        console.log("page: " + page);
        setCurrentPage(page + 1)
    }

    const getSelectedCourier = (id) => {
        for (var i = 0; i < couriers.items.length; i++) {
            if (couriers.items[i].id.toString() == id) {
                return couriers.items[i]
            }
        }
    }

    return (
        <Grid container justifyContent="center" >
            {
                couriers
                    ?
                    <>
                        <Grid item xs={12} lg={10} style={{ textAlign: "right" }}>
                            <Button
                                color="primary"
                                component={Link}
                                to={{ pathname: `courier` }}
                                variant={"contained"}
                            >
                                Kurye Ekle
                            </Button>
                        </Grid>
                        <Grid item xs={12} lg={10} sx={{ mt: 2 }}>
                            <DataGrid
                                autoHeight
                                rows={couriers.items}
                                columns={columns}
                                paginationMode="server"
                                page={currentPage - 1}
                                pageSize={couriers._meta.per_page}
                                onPageChange={(page, details) => handlePageChange(page, details)}
                                onRowClick={(params) => history.push("courier", {
                                    courier: getSelectedCourier(params.id)
                                })}
                                components={{
                                    NoRowsOverlay: (params) => {
                                        return <div style={{
                                            //backgroundColor: "blue",
                                            textAlign: "center",
                                            transform: "translateY(500%)"
                                        }}><Typography variant="subtitle1">Kayıtlı kurye bulanmamaktır</Typography></div>;
                                    }
                                }}
                            />
                        </Grid>
                    </>
                    :
                    <Loading />
            }
        </Grid>
    )



}
export default Courier
