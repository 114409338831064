import {
    LOADING_DATA,
    RETURN_TO_INITIAL,
    SET_ADMIN_USER,
    SET_COURIERS,
} from '../types';

const initialState = {
    loading: false,
    couriers: null,
};
export default function (state = initialState, action) {
    switch (action.type) {
        /*
        case PRODUCT_REVIEWED:
            console.log("action.payload" + JSON.stringify(action.payload));
            let pending = { ...state.pendingProducts };
            let remainder_products = pending[action.payload.product.provider.id].filter(item => item.id !== action.payload.product.id)
            if (Object.keys(pending).length === 1 && pending[Object.keys(pending)[0]].length === 1) {
                return {
                    ...state,
                    pendingProducts: {}
                }
            }
            if (remainder_products.length === 0) {
                delete pending[action.payload.product.provider.id]
                return {
                    ...state,
                    pendingProducts: pending
                }
            }
            else {
                return {
                    ...state,
                    pendingProducts: {
                        ...state.pendingProducts,
                        [action.payload.product.provider.id]: state.pendingProducts[action.payload.product.provider.id].filter(item => item.id !== action.payload.product.id)
                    }
                }
            }

        case SET_PENDING_PROVIDER_VISUALS:
            return {
                ...state,
                pendingProviderVisuals: Array.isArray(action.payload)
                    ?
                    action.payload
                    :
                    [...state.pendingProviderVisuals, action.payload],
                loading: false
            };
        */
        case SET_COURIERS:
            return {
                ...state,
                couriers: action.payload,
                loading: false
            }

        case SET_ADMIN_USER:
            return {
                ...state,
                loading: false,
                ...action.payload
            };

        case LOADING_DATA:
            return {
                ...state,
                loading: action.payload
            };
        case RETURN_TO_INITIAL:
            return { ...initialState };
        default:
            return state;
    }
}