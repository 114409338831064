import React from 'react'
import Icon from '@mui/material/Icon'

function Service({ content }) {
  return (
    <div className="mi-service">
      <div
        className="mi-service-wrapper"
        style={{ backgroundColor: content.bgColor }}
      >
        <span className="mi-service-icon">
          <Icon>{content.icon}</Icon>
        </span>
        <h6>{content.title}</h6>
      </div>

      {/*<p>{content.details}</p>*/}
    </div>
  )
}

export default Service
