import { Route, Switch, useHistory } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import './App.scss'
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from '@mui/material/styles'
import { Grid } from '@mui/material'
import themeFile from './util/theme'
import jwtDecode from 'jwt-decode'
import { HelmetProvider } from 'react-helmet-async'

// Redux
import { useSelector, useDispatch } from 'react-redux'
import {
  setAuthLoading,
  logoutUser,
  getUserData,
  resetStates,
} from './redux/actions'

import AuthRoute from './components/AuthRoute'

// components
import AdminDrawer from './components/AdminDrawer'
import Notification from './components/Notification'
import SimpleModal from './components/Modal'

// Pages
import Landing from './pages/landing'
import WhatWeDo from './pages/whatWeDo'
import Contact from './pages/contact'
import Portfolio from './pages/portfolio'
import EmailConfirm from './pages/emailConfirm'
import Login from './pages/login'
import axiosInstance from './redux/actions/axiosInstance'

//static
//import LOADING_GIF from './images/general/loading.gif';
import LightModeIcon from '@mui/icons-material/LightMode'
import SignalWifiConnectedNoInternet4Icon from '@mui/icons-material/SignalWifiConnectedNoInternet4'

const BREAKPOINTS = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1400,
  xl: 1900,
}

const breakpointsValues = {
  breakpoints: {
    values: BREAKPOINTS,
  },
}

let theme = createTheme({ ...themeFile /* ...breakpointsValues*/ })
theme = responsiveFontSizes(theme)

//console.log("authenticated 21: " + auth.authenticated);

const App = () => {
  const dispatch = useDispatch()
  //let location = useLocation();
  //console.log("location.pathname: " + location.pathname);
  //console.log("APP.JS !!");
  let history = useHistory()
  //console.log("app.js pathname: " + history.location.pathname);
  //console.log("auth loading 111: " + auth.loading);

  const auth = useSelector((state) => state.auth)
  //const errors = useSelector((state) => state.UI.errors)

  const [lightMode, setLightMode] = useState(true)

  lightMode
    ? document.body.classList.add('light')
    : document.body.classList.remove('light')

  const handleMode = () => {
    if (!lightMode) {
      setLightMode(true)
      document.body.classList.add('light')
    } else {
      setLightMode(false)
      document.body.classList.remove('light')
    }
  }

  const [errorModalOpen, setErrorModalOpen] = useState(false)

  const logOut = () => {
    dispatch(logoutUser())
  }

  useEffect(() => {
    //console.log('checking pathh: ' + history.location.pathname)
    const path = localStorage.getItem('PATH')
    if (path) {
      console.log('getting user data..')
      dispatch(getUserData(path))
    }
    if (
      !path &&
      (history.location.pathname === '/' ||
        history.location.pathname === '/login' ||
        history.location.pathname === '/what-we-do' ||
        history.location.pathname === '/our-work' ||
        history.location.pathname === '/contact'
        )
    ) {
      //console.log("no path founded..")
      dispatch(setAuthLoading(false))
    }
    if (
      !path &&
      history.location.pathname !== '/' &&
      !history.location.pathname.startsWith('/email/confirm/')
    ) {
      //console.log("unauthorized attempt..");
      //console.log("redirect to login..");
      //history.push('/login')
    }

    /*
    const token = localStorage.JWT
    console.log("token check..");
    if (token) {
      //console.log("token founded in local..");
      //dispatch(setAuthLoading(true));
      const decodedToken = jwtDecode(token);
      if (decodedToken.exp * 1000 < Date.now()) {
        //console.log("token expired..");
        dispatch(logoutUser());
        //window.location.href = '/login';
        history.push("/login")
      } else {
        //console.log("token valid..");
        //const roles = JSON.parse(localStorage.ROLES);
        //const ID = localStorage.getItem('ID')
        axiosInstance.defaults.headers.common['Authorization'] = token;
        //dispatch(getUserDataPath(ID));
        dispatch(getUserData(localStorage.getItem("PATH")))
      }
    }
    if (!token) {
      dispatch(setAuthLoading(false));
    }
    if (!token && history.location.pathname !== "/" && !history.location.pathname.startsWith("/email/confirm/")) {
      //console.log("unauthorized attempt..");
      //console.log("redirect to login..");
      history.push("/login")
    }
    */
  }, [dispatch])

  useEffect(() => {
    //console.log("authenticated 121: " + auth.authenticated);
    //console.log("auth loading 121: " + auth.loading);
    if (!auth.loading) {
      // authenticated
      if (auth.authenticated) {
        if (
          history.location.pathname === '/' ||
          history.location.pathname === '/login'
        ) {
          if (auth.role && (auth.role.id === 1 || auth.role.id === 2)) {
            history.push('/admin')
          }
        }
      }

      // not authenticated
      if (!auth.authenticated) {
        console.log('redirect to home..')
        history.replace('/')
        dispatch(resetStates())
      }
    }
  }, [auth.authenticated])

  const handleErrorModalClose = () => {}

  //console.log("auth loading: " + auth.loading)

  return (
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <Notification />
        <div className="light-mode">
          <span className="icon">
            <LightModeIcon color={lightMode ? 'primary' : 'secondary'} />
            {/*
          <Icon.Sun />
            */}
          </span>
          <button
            className={
              lightMode ? 'light-mode-switch active' : 'light-mode-switch'
            }
            onClick={() => handleMode()}
          ></button>
        </div>
        {!auth.loading ? (
          <Switch>
            <Route exact path="/">
              <Landing lightMode={lightMode} />
            </Route>
            <Route path="/what-we-do">
              <WhatWeDo lightMode={lightMode} />
            </Route>
            <Route path="/our-work">
              <Portfolio lightMode={lightMode} />
            </Route>
            <Route path="/contact">
              <Contact lightMode={lightMode} />
            </Route>

            {/*
            <Route
              exact
              path="/email/confirm/:token"
              component={EmailConfirm}
            />
            <Route path="/login" component={Login} />

            {auth.authenticated &&
            (auth.role.id === 1 || auth.role.id === 2) ? (
              <AuthRoute
                logOut={() => logOut()}
                path="/admin"
                component={AdminDrawer}
              />
            ) : null}
              */}
          </Switch>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0,0,0,0.15)',
            }}
          >
            <img src="" />
          </div>
        )}

        <SimpleModal
          open={errorModalOpen}
          xs={10}
          md={8}
          lg={6}
          renderHeader={false}
          //paperStyle={{ height: window.innerHeight / 2.15 }}
          bodyStyle={{ overflow: 'hidden', justifyContent: 'center' }}
          //bodyStyle={{ height: window.innerHeight / 2.1 }}
          //headerStyle={{ justifyContent: "space-between" }}
          onCancelClick={handleErrorModalClose}
          //styles={{ width: window.innerWidth / 4, height: window.innerHeight / 4 }}
          headerText={'Error'}
          body={
            <div
              style={{
                ...theme.styles.centerColContent,
                width: '100%',
                minHeight: 200,
                padding: 10,
              }}
            >
              <SignalWifiConnectedNoInternet4Icon
                sx={{ color: theme.palette.primary.main, fontSize: 90 }}
              />
              <div
                style={{
                  ...theme.styles.centerColContent,
                  width: '80%',
                  textAlign: 'center',
                }}
              >
                <p style={{ color: theme.palette.primary.main }}>
                  Üzgünüz, bir hata oluştu ve şuan için bu işleme devam
                  edemiyoruz
                </p>
                <p>
                  Lütfen internet bağlantınızı kontrol ediniz. Eğer internet
                  bağlantınızda bir problem yoksa sayfayı yenileyiniz
                </p>
              </div>
            </div>
          }
        />
      </HelmetProvider>
    </ThemeProvider>
  )
}

export default App
