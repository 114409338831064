import axios from 'axios';
import store from '../store';
import { SET_ERRORS } from '../types';


export const URI = process.env.NODE_ENV == "production" ? process.env.API_URI : "http://localhost:5000"

const axiosInstance = axios.create({
    //withCredentials: true,
    baseURL: URI
})

axiosInstance.interceptors.response.use(response => response, error => {
    const status = error.response ? error.response.status : null
    console.log("ERROR STATUS: " + status)
    //store.dispatch({ type: SET_ERRORS, payload: { error: { status: status, url: error.config.url, timestamp: new Date() } } })
    //const state = store.getState();
    //console.log("STATE: " + JSON.stringify(state));
    /*
   if (status === UNAUTHORIZED) {
       console.log("USER IS UNAUTHORIZED NOW !!");
      
       return refreshToken()
         .then(freshToken => {
           console.log("FRESH TOKEN: " + freshToken);
           error.config.headers['Authorization'] = 'Bearer ' + freshToken; // new header new token
           //error.config.baseURL = undefined;
           console.log("ORIGINAL REQUEST AFTER: " + JSON.stringify(error.config));
           return axiosInstance.request(error.config);
         })
         .catch(err => reject(err));
   }
   */
    return Promise.reject(error);
});

export default axiosInstance
