import {
  SET_FLASH_MESSAGE,
  SET_ADMIN_USER,
  SET_AUTH_LOADING,
  SET_UNAUTHENTICATED,
  OPEN_WEBSOCKET,
  SET_AUTHENTICATED,
  RETURN_TO_INITIAL,
} from '../types'
import axiosInstance from './axiosInstance'

export const loginUser = (userData) => (dispatch) => {
  dispatch({ type: SET_AUTH_LOADING, payload: true })
  console.log('baseurl: ' + axiosInstance.defaults.baseURL)

  dispatch({
    type: SET_ADMIN_USER,
    payload: { name: 'admin' },
  })
  dispatch({
    type: SET_AUTHENTICATED,
    payload: { role: { id: 1, role: 'SuperAdmin' } },
  })
  /*
    axiosInstance
        .get('/token', { auth: userData })
        .then((res) => {
            console.log("LOGIN RESPONSE: " + JSON.stringify(res.data));
            //setAuthorizationHeader(res.data.token, res.data.id, res.data.path);
            setLocalStorageVariables(res.data.path)
            //dispatch(getUserDataPath(res.data.id));
            dispatch(getUserData(res.data.path))
            //dispatch({ type: CLEAR_ERRORS });
            //history.push("/admin");
        })
        .catch((err) => {
            console.log("ERROR: " + err);
            dispatch({ type: SET_AUTH_LOADING, payload: false });
            dispatch({
                type: SET_FLASH_MESSAGE,
                payload: {
                    title: "Hatalı Giriş",
                    msg: err.response.data.message,
                    type: "error"
                }
            });
        });
        */
}

export const getUserData = (path) => (dispatch) => {
  //console.log("getUserData: " + path);
  axiosInstance
    .get(path)
    .then((res) => {
      //console.log("res.data:" + JSON.stringify(res.data));

      if (res.data.role.id === 1 || res.data.role.id === 2) {
        dispatch({
          type: SET_ADMIN_USER,
          payload: res.data,
        })
      }

      /*
      dispatch({
        type: OPEN_WEBSOCKET,
        payload: { id: res.data.id },
      })
      */

      dispatch({
        type: SET_AUTHENTICATED,
        payload: { role: res.data.role },
      })
    })
    .catch((err) => {
      console.log(err)
    })
}

export const setUserPass = (token, password) => {
  return axiosInstance.post('/auth/password/' + token, { password })
}

export const confirEmail = (token) => {
  return axiosInstance.get('/email/confirm/' + token)
}

export const setAuthLoading = (value) => (dispatch) => {
  dispatch({ type: SET_AUTH_LOADING, payload: value })
}

export const resetStates = (value) => (dispatch) => {
  dispatch({ type: RETURN_TO_INITIAL })
}

export const logoutUser = () => (dispatch) => {
  console.log('logging out..')
  //dispatch({ type: SET_AUTH_LOADING, payload: true });
  axiosInstance
    .get('/logout')
    .then(({ data }) => {
      console.log('logout res: ' + JSON.stringify(data))
      //localStorage.removeItem('ID');
      //localStorage.removeItem('JWT');
      localStorage.removeItem('PATH')
      //localStorage.removeItem('ROLES');
      //delete axiosInstance.defaults.headers.common['Authorization'];
      dispatch({ type: SET_UNAUTHENTICATED })
      //dispatch({ type: SET_AUTH_LOADING, payload: false });
    })
    .catch((err) => console.log(err))
}

const setLocalStorageVariables = (path) => {
  localStorage.setItem('PATH', path)
}

const setAuthorizationHeader = (token, id, path) => {
  const JWT = `Bearer ${token}`
  axiosInstance.defaults.headers.common['Authorization'] = JWT
  localStorage.setItem('JWT', JWT)
  localStorage.setItem('ID', id)
  localStorage.setItem('PATH', path)
}
