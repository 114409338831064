import axiosInstance from '../redux/actions/axiosInstance'
import React, { Suspense, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import Layout from '../components/Layout'
import Particle from '../components/Particle'
import Socialicons from '../components/Socialicons'
import Spinner from '../components/Spinner'

function Landing({ lightMode }) {
  const [information, setInformation] = useState('')

  useEffect(() => {
    axiosInstance.get('/api/information').then((response) => {
      setInformation(response.data)
    })
  }, [])

  return (
    <Layout lightMode={lightMode}>
      <Helmet>
        <title>Home - Cogito Technology</title>
        <meta
          name="description"
          content="Cogito Technology Homepage"
        />
      </Helmet>
      <Suspense fallback={<Spinner />}>
        <div className="mi-home-area mi-padding-section">
          <Particle lightMode={lightMode} />
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10 col-12">
                <div className="mi-home-content">
                  <h1>
                    Welcome to{' '}
                    <span className="color-theme">{information.name}</span>
                  </h1>
                  <p>{information.aboutContent}</p>
                  {/*<Socialicons bordered />*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Suspense>
    </Layout>
  )
}

export default Landing
