import React, { useState, useEffect } from 'react'
// MUI Stuff

import { Grid, TextField, Button, CircularProgress, Paper } from '@mui/material';

import { useTheme } from '@mui/material/styles';

// Redux stuff
import { useSelector, useDispatch } from 'react-redux'
import { setAuthLoading, loginUser } from '../redux/actions';

// Components
import Navbar from '../components/Navbar';

//static

const styles = {
    container: {
        width: "100%",
        height: "100%",
    },
    form: {
        textAlign: 'center',
        height: '100%',
        backgroundColor: "#ddd"
    },
    appLogo: {
        width: "100%",
        height: "auto",
        margin: '20px auto 20px auto'
    },
    pageTitle: {
        margin: '10px auto 10px auto',
    },
    textField: {
        width: "75%",
        margin: '10px auto 10px auto'
    },
    button: {
        marginTop: 2.5,
        width: "35%",
        position: 'relative'
    },
    progress: {
        position: 'absolute',
        color: '#ffcc00'
    },
    customError: {
        color: 'red',
        fontSize: '0.8rem',
        marginTop: 10,
    }

};

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [formErrors, setFormErrors] = useState({ email: '', password: '' });
    const [emailValid, setEmailValid] = useState(false);
    const [passwordValid, setPasswordValid] = useState(false);
    const [formValid, setFormValid] = useState(false);

    const dispatch = useDispatch()

    const loading = useSelector(state => state.auth.loading);


    const handleSubmit = (event) => {
        event.preventDefault();
        const creds = {
            username: email,
            password: password
        };
        dispatch(loginUser(creds));
    }

    const validateField = (fieldName, value) => {
        let fieldValidationErrorsTmp = formErrors ? formErrors : null;
        let emailValidTmp = emailValid ? emailValid : null;
        let passwordValidTmp = passwordValid ? passwordValid : null;

        switch (fieldName) {
            case 'email':
                emailValidTmp = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                fieldValidationErrorsTmp.email = emailValidTmp ? '' : ' geçersiz mail';
                break;
            case 'password':
                passwordValidTmp = value.length > 3;
                fieldValidationErrorsTmp.password = passwordValidTmp ? '' : ' şifre çok kısa';
                break;
            default:
                break;
        }

        setFormErrors(fieldValidationErrorsTmp);
        setEmailValid(emailValidTmp);
        setPasswordValid(passwordValidTmp);
    }

    const validateForm = () => {
        setFormValid((emailValid && passwordValid))
    }


    useEffect(() => {
        console.log("loading initial: " + loading);
        if (loading) {
            console.log("set loading to false");
            dispatch(setAuthLoading(false))
        }
    }, [])



    useEffect(() => {
        //console.log("checking form valid..");
        validateForm();
    }, [emailValid, passwordValid])

    useEffect(() => {
        if (email) {
            //console.log("validating email field valid..");
            validateField("email", email);
        }
    }, [email])


    useEffect(() => {
        if (password) {
            //console.log("validating password field valid..");
            validateField("password", password);
        }
    }, [password])



    const handleChange = (event) => {
        if (event.target.name === 'email') {
            setEmail(event.target.value);
        }

        if (event.target.name === 'password') {
            setPassword(event.target.value);
        }
    }

    //console.log("login rendering..")
    //console.log("formErrors:" + JSON.stringify(formErrors));
    //console.log("isFormValid: " + formValid);

    return (
        <div style={styles.container}>
            <Navbar />
            <Grid container justifyContent="center" alignItems="center" sx={styles.form}>
                <Grid item xs={10} md={7} lg={5}>
                    <Paper style={{ padding: 25 }}>
                        <img src="" alt="logo" style={styles.appLogo} />
                        <form noValidate onSubmit={(event) => handleSubmit(event)}>
                            <TextField
                                error={formErrors.email !== ''}
                                helperText={formErrors.email}
                                id="email"
                                name="email"
                                type="email"
                                label="Email"
                                sx={styles.textField}
                                value={email}
                                onChange={(event) => handleChange(event)}
                                fullWidth />
                            <TextField
                                error={formErrors.password !== ''}
                                helperText={formErrors.password}
                                id="password"
                                name="password"
                                type="password"
                                label="Şifre"
                                sx={styles.textField}
                                value={password}
                                onChange={(event) => handleChange(event)}
                                fullWidth />

                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                sx={styles.button}
                                disabled={!formValid}>
                                Giriş
                                {
                                    loading && (
                                        <CircularProgress sx={styles.progress} size={30} />
                                    )

                                }
                            </Button>
                        </form>
                    </Paper>
                </Grid>

            </Grid>
        </div>

    )
}

export default Login;




