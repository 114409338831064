import {
    SET_AUTHENTICATED,
    SET_UNAUTHENTICATED,
    SET_AUTH_LOADING,
} from '../types';

const initialState = {
    authenticated: false,
    loading: true,
    role: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_AUTHENTICATED:
            return {
                ...state,
                authenticated: true,
                role: action.payload.role,
                loading: false
            };
        case SET_UNAUTHENTICATED:
            return { ...initialState, loading: false };

        case SET_AUTH_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        default:
            return state;
    }
}