import React, { useState, useEffect } from 'react'
import {
  Box,
  Stepper,
  Step,
  StepButton,
  StepLabel,
  StepIcon,
  Button,
  Typography,
  Icon,
  Paper,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'

function CustomStepIcon(props) {
  //const classes = useStyles();
  const { active, completed, icon, name } = props

  //console.log('props icon: ' + icon)
  console.log('step icon: ' + name)

  return (
    <div
    /*className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}*/
    >
      <Icon style={{ fontSize: '2rem', color: active ? '#008cff' : 'grey' }}>
        {name}
      </Icon>
    </div>
  )
}

function ProcessStepper({ steps }) {
  const [activeStep, setActiveStep] = useState(0)
  const [completed, setCompleted] = useState({})

  const theme = useTheme()

  const totalSteps = () => {
    return steps.length
  }

  const completedSteps = () => {
    return Object.keys(completed).length
  }

  const isLastStep = () => {
    return activeStep === totalSteps() - 1
  }

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps()
  }

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1
    setActiveStep(newActiveStep)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleStep = (step) => () => {
    setActiveStep(step)
  }

  const handleComplete = () => {
    const newCompleted = completed
    newCompleted[activeStep] = true
    setCompleted(newCompleted)
    handleNext()
  }

  const handleReset = () => {
    setActiveStep(0)
    setCompleted({})
  }

  console.log('steps:' + steps)

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ p: 3 }}>
        <div className="row">
          <div className="col-xl-1 col-lg-2 col-md-3 d-flex justify-content-center stepper-col">
            <Stepper orientation="vertical" nonLinear activeStep={activeStep}>
              {steps
                ? steps.map((step, index) => (
                    <Step
                      key={step.title}
                      completed={completed[index]}
                      sx={{
                        '&.MuiStep-root': {
                          cursor: 'pointer',
                        },
                      }}
                    >
                      <StepLabel
                        StepIconComponent={CustomStepIcon}
                        StepIconProps={{ name: step.name }}
                        color="inherit"
                        onClick={handleStep(index)}
                      >
                        {step.title}
                      </StepLabel>
                    </Step>
                  ))
                : null}
            </Stepper>
          </div>

          <div className="col-xl-11 col-lg-10 col-md-9 d-flex justify-content-center align-items-center text-center">
            <h6
              className="process-stepper"
              //style={theme.styles.whiteTextShadow}
            >
              {steps[activeStep].details}
            </h6>

            {/*
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleNext} sx={{ mr: 1 }}>
                Next
              </Button>
              {activeStep !== steps.length &&
                (completed[activeStep] ? (
                  <Typography
                    variant="caption"
                    sx={{ display: 'inline-block' }}
                  >
                    Step {activeStep + 1} already completed
                  </Typography>
                ) : (
                  <Button onClick={handleComplete}>
                    {completedSteps() === totalSteps() - 1
                      ? 'Finish'
                      : 'Complete Step'}
                  </Button>
                ))}
            </Box>
                */}
          </div>
        </div>
      </Paper>
    </Box>
  )
}
export default ProcessStepper
