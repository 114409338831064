import {
    SET_ERRORS,
    CLEAR_ERRORS,
    LOADING_UI,
    STOP_LOADING_UI,
    SET_FLASH_MESSAGE,
    RETURN_TO_INITIAL
} from '../types';

const initialState = {
    loading: false,
    errors: { user_service: [], provider_service: [], order_service: [], notification_service: [] },
    flashMessage: null  //{ msg: "Ürün başarı ile güncellendi", type: "success" }
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_FLASH_MESSAGE:
            return {
                ...state,
                flashMessage: action.payload
            }
        case SET_ERRORS:
            return {
                ...state,
                loading: false,
                errors: { ...state.errors, [action.payload.service]: [...state.errors[action.payload.service], action.payload.error] }
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                loading: false,
                errors: null
            };
        case LOADING_UI:
            return {
                ...state,
                loading: true
            };
        case STOP_LOADING_UI:
            return {
                ...state,
                loading: false
            };
        case RETURN_TO_INITIAL:
            return { ...initialState };
        default:
            return state;
    }
}