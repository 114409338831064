import axiosInstance from '../redux/actions/axiosInstance'
import React, { useEffect, useState } from 'react'
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
//import ProgressiveImage from 'react-progressive-image'
import { Link, NavLink } from 'react-router-dom'

function Header({ lightMode }) {
  const [information, setInformation] = useState('')
  const [navigationToggler, setNavigationToggler] = useState(false)

  const handleNavigationToggler = () => {
    setNavigationToggler(!navigationToggler)
  }

  useEffect(() => {
    axiosInstance.get('/api/information').then((response) => {
      setInformation(response.data)
      //console.log('header lightMode: ' + lightMode)
    })
  }, [])

  return (
    <nav className={navigationToggler ? 'mi-header is-visible' : 'mi-header'}>
      <button onClick={handleNavigationToggler} className="mi-header-toggler">
        {!navigationToggler ? (
          <MenuIcon />
        ) : (
          <CloseIcon />
        )}
      </button>
      <div className="mi-header-inner">
        <div className="mi-header-image">
          <Link to="/">
            {
              <img
                src={
                  !lightMode
                    ? information.brandImageWhite
                    : information.brandImageDark
                }
                placeholder="/images/about-image-placeholder.png"
              />
              /*
            <ProgressiveImage
              src={information.brandImage}
              placeholder="/images/about-image-placeholder.png"
            >
              {(src) => <img src={src} alt="brandimage" />}
            </ProgressiveImage>
              */
            }
          </Link>
        </div>

        <ul className="mi-header-menu">
          <li>
            <NavLink to="/" exact={true}>
              <span>Home</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/what-we-do">
              <span>What We Do</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/our-work">
              <span>Our Work</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/contact">
              <span>Contact</span>
            </NavLink>
          </li>
        </ul>
        <p className="mi-header-copyright">
          &copy; {new Date().getFullYear()}{' '}
          <b>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://nuclearthemes.com"
            >
              CogitoTech
            </a>
          </b>
        </p>
      </div>
    </nav>
  )
}

export default Header
