import { LOADING_DATA, SET_FLASH_MESSAGE, SET_COURIERS } from '../types'
import axiosInstance from './axiosInstance'

export const sendClientMail = (data) => (dispatch) => {
    //dispatch({ type: LOADING_DATA, payload: true })
    axiosInstance
      .post('/api/client/send_mail', data)
      .then((res) => {
        console.log('data: ' + JSON.stringify(res.data))
        /*
        dispatch({
          type: SET_FLASH_MESSAGE,
          payload: {
            title: 'Yeni Kurye',
            msg:
              res.data.name +
              ' ' +
              res.data.last_name +
              ' adlı kurye başarı ile eklendi',
            type: 'success',
          },
        })
        */
      })
      .catch((err) => console.log(err))
  }