import React, { useState, useEffect } from 'react'
import { NotificationContainer, NotificationManager } from 'react-notifications';

import { useSelector, useDispatch } from 'react-redux'
import { setFlashMessage } from '../redux/actions';

import 'react-notifications/lib/notifications.css';


const Notification = () => {
    const flashMessage = useSelector(state => state.UI.flashMessage);

    useEffect(() => {
        if (flashMessage) {
            createNotification(flashMessage)
        }
    }, [flashMessage])

    const createNotification = (message) => {
        console.log("msg type: " + message.type);
        if (message.type === "info") {
            NotificationManager.info(message.msg, message.title, 4000);
        }
        else if (message.type === "success") {
            NotificationManager.success(message.msg, message.title, 4000);
        }
        else if (message.type === "warning") {
            NotificationManager.warning(message.msg, message.title, 4000);
        }
        else if (message.type === "error") {
            NotificationManager.error(message.msg, message.title, 4000, () => {
                alert('callback');
            });
        }
    };

    /*
    componentDidUpdate(prevProps, prevState) {
        const { flashMessage } = this.props;


        if (!prevProps.flashMessage && flashMessage) {
            console.log("creating notification request:" + JSON.stringify(flashMessage));
            this.createNotification(flashMessage);

            
            setTimeout(
                () => this.props.setFlashMessage(null),
                3000
            );
        }
    }
    */


    return (
        <div>
            <NotificationContainer />
        </div>
    );

}


export default Notification;