import React, { useEffect, useCallback } from 'react';
import Particles from 'react-tsparticles';
import { loadFull } from "tsparticles";

function Particle({ lightMode }) {
  useEffect(() => {
    //console.log('lightMode:' + lightMode)
  }, [])

  const particlesInit = useCallback(async (engine) => {
    console.log(engine)
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine)
  }, [])

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container)
  }, [])

  const paramConfig = {
    particles: {
      number: {
        value: 160,
        density: {
          enable: false,
        },
      },
      color: {
        value: '#ffffff',
      },
      opacity: {
        value: 0.1,
      },
      size: {
        value: 5,
        random: true,
        anim: {
          speed: 4,
          size_min: 0.3,
        },
      },
      line_linked: {
        enable: false,
      },
      move: {
        enable: true,
        random: true,
        speed: 1,
        direction: 'top',
        out_mode: 'out',
      },
    },
  }

  const paramConfigLight = {
    particles: {
      number: {
        value: 160,
        density: {
          enable: false,
        },
      },
      color: {
        value: '#000000',
      },
      opacity: {
        value: 0.1,
      },
      size: {
        value: 5,
        random: true,
        anim: {
          speed: 4,
          size_min: 0.3,
        },
      },
      line_linked: {
        enable: false,
      },
      move: {
        enable: true,
        random: true,
        speed: 1,
        direction: 'top',
        out_mode: 'out',
      },
    },
  }
  return (
    <Particles
      init={particlesInit}
      loaded={particlesLoaded}
      className="mi-home-particle"
      options={lightMode ? paramConfigLight : paramConfig}
    />
  )
}

export default Particle
