import React, { useState, useEffect, useRef } from 'react'

// Redux stuff
import { useSelector, useDispatch } from 'react-redux';
import {} from '../../redux/actions';

import { useHistory, useLocation } from 'react-router-dom';

// MUI Stuff
import {
    Grid, FormControl, Divider, TextField, Paper, Button, Typography, InputLabel, Select, MenuItem
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { fromBlob } from 'image-resize-compress';
import { isValidPhoneNumber } from 'react-phone-number-input';

// Icons
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CropIcon from '@mui/icons-material/Crop';

// Components 
import Loading from '../../components/Loading';
import Dropzone from '../../components/dropzone/Dropzone';
import MyCropComponent from '../../components/MyCropComponent';
import SimpleModal from '../../components/Modal';

import { getCourierImage } from '../../util/functions'



const styles = {
    confirmBtn: {
        height: 40,
        color: "white",
    },

    removeProductBtn: {
        height: 40,
        color: "white"
    },
    addIcon: {
        color: 'info.main'
    },
    productAttrText: {
        margin: 0,
        fontSize: "1em"

    },
    paperContainer: {
        width: '100%',
        height: "100%",
        backgroundColor: "grey",
    },
    hideBorder: {
        '&.MuiAccordionDetails-root:before': {
            display: 'none',
        },
    },
    dangerColor: {
        color: 'danger.main'
    },
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}

const COURIER_STATUSES = [
    { id: -1, label: "İlişiği Kesildi" },
    { id: 0, label: "İnaktif" },
    { id: 1, label: "Aktif" },
]


const CourierForm = () => {
    const history = useHistory();
    let location = useLocation();
    const { courier } = location.state || {};
    const [courierModel, setCourierModel] = useState({
        id: null,
        name: "",
        last_name: "",
        email: "",
        phone: {
            countryCode: "+90",
            value: "",
        },
        image: {
            square: null,
            squareTmp: null,
            squareTmpErrors: null,
        },
        address: {
            city_id: "",
            county_id: "",
            text: ""
        },
        vehicle_id: "",
        status: 1
    });
    const phoneRef = useRef(null)
    const [formErrors, setFormErrors] = useState({ phone: null })
    const [cropModalOpen, setCropModalOpen] = useState(false)

    const [dialog, setDialog] = useState({
        open: false,
        type: -1,
        title: "",
        text: "",
    })


    const dispatch = useDispatch()

    const vehicles = useSelector(state => state.admin.vehicles);
    const cities = useSelector(state => state.admin.cities);
    const couriers = useSelector(state => state.admin.couriers);
    const loading = useSelector(state => state.admin.loading);

    const theme = useTheme();

    useEffect(() => {
        if (!vehicles || !Array.isArray(vehicles)) {
            console.log("getting vehicles")
            //dispatch(getVehicles())
        }
        if (!cities && !courierModel.id) {
            //dispatch(getCities())
        }
    }, [])

    useEffect(() => {
        if (formErrors.email) {
            setFormErrors({ ...formErrors, email: null })
        }
    }, [courierModel.email])

    useEffect(() => {
        if (formErrors.phone) {
            setFormErrors({ ...formErrors, phone: null })
        }
    }, [courierModel.phone.value])

    useEffect(() => {
        if (courier) {
            console.log("courier: " + JSON.stringify(courier))
            setCourierModel({
                id: courier.id,
                name: courier.name,
                last_name: courier.last_name,
                email: courier.email,
                phone: {
                    countryCode: courier.phone ? courier.phone.substring(0, 3) : "+90",
                    value: courier.phone ? courier.phone.substring(3) : ""
                },
                image: {
                    square: null,
                    squareTmp: getCourierImage(courier.id),
                    squareTmpErrors: null,
                },

                address: {
                    city_id: courier.city.id,
                    county_id: courier.county.id,
                    text: courier.address
                },
                vehicle_id: courier.vehicle.id,
                status: courier.status

            })
        }
    }, [courier])

    useEffect(() => {
        if (courierModel.image.squareTmp) {
            let img = new Image();
            img.src = typeof courierModel.image.squareTmp !== 'string' ? URL.createObjectURL(courierModel.image.squareTmp) : courierModel.image.squareTmp;
            img.onload = () => {
                if (img.width < 128 || img.height < 128) {
                    setCourierModel({ ...courierModel, image: { ...courierModel.image, squareTmp: null, squareTmpErrors: "Yüklemiş olduğunuz görsel minimum 128x128 olarak belirtilen en boy oranından küçüktür lütfen başka bir görsel yükleyiniz" } })
                }
                else if (img.width !== img.height) {
                    setCourierModel({ ...courierModel, image: { ...courierModel.image, squareTmpErrors: "Yüklemiş olduğunuz görsel istenilen boyutlara uymamaktadır, dilerseniz kırpma aracını kullanarak görseli istenilen boyutlara getirebilirsiniz" } })
                }
                else {
                    console.log("square image has valid dimensions..")
                    if (img.width !== 128) {
                        fromBlob(courierModel.image.squareTmp, 100, 128, 128, "jpeg").then((resized_blob) => {
                            setCourierModel({ ...courierModel, image: { ...courierModel.image, squareTmp: resized_blob, squareTmpErrors: null } })
                        });
                    }
                    else {
                        setCourierModel({ ...courierModel, image: { ...courierModel.image, squareTmpErrors: null } })
                    }

                }
            }
        }
    }, [courierModel.image.squareTmp])

    const selectCourierImage = (file) => {
        setCourierModel({ ...courierModel, image: { ...courierModel.image, squareTmp: file } })
    }

    const renderDropzoneContent = (dragOver, dragEnter, dragLeave, fileDrop, fileInputClicked) => {
        return (
            <Grid item style={{ width: 148, height: 148, backgroundColor: "#4aa1f31f" }}>
                <Button
                    style={{ width: "100%", height: "100%", fontSize: 12 }}
                    variant="outlined"
                    color="primary"
                    onDragOver={dragOver}
                    onDragEnter={dragEnter}
                    onDragLeave={dragLeave}
                    onDrop={fileDrop}
                    onClick={fileInputClicked}>
                    Minumum 128x128 en boy oranına sahip görseli yüklemek için tıklayın
                </Button>
            </Grid >
        );
    }

    const setCroppedImage = (blob) => {
        setCourierModel({ ...courierModel, image: { ...courierModel.image, squareTmp: blob } })
    }

    const getCountiesOfCity = () => {
        for (var i = 0; i < cities.length; i++) {
            if (cities[i].id === courierModel.address.city_id) {
                return cities[i].counties;
            }
        }
        return []
    }

    const getQuartersOfCounty = () => {
        for (var i = 0; i < cities.length; i++) {
            for (var y = 0; y < cities[i].counties.length; y++) {
                if (cities[i].counties[y].id === courierModel.address.county_id) {
                    return cities[i].counties[y].quarters;
                }
            }
        }
        return []
    }

    const checkVehicleDisabled = () => {
        for (var i = 0; i < couriers.length; i++) {
            if (couriers[i].county.id != courierModel.address.county_id) {
                return true
            }
        }
        return false
    }

    const vehicle_markup = vehicles && Array.isArray(vehicles) ?
        vehicles.map((vehicle) =>
            !vehicle.county || vehicle.county.id == courierModel.address.county_id
                ?
                <MenuItem key={"vehicle-" + vehicle.id} value={vehicle.id}>{vehicle.licence_plate}</MenuItem>
                :
                null
        )
        :
        null

    const status_markup = COURIER_STATUSES.map((status) => <MenuItem key={"status-" + status.id} value={status.id}>{status.label}</MenuItem>)


    const city_markup = cities ?
        cities.map((city) => <MenuItem key={"city-" + city.id} value={city.id}>{city.name}</MenuItem>)
        :
        null

    const county_markup = cities ?
        getCountiesOfCity(cities).map((county) => <MenuItem key={"county-" + county.id} value={county.id}>{county.name}</MenuItem>)
        :
        null


    const checkValid = () => {
        if (courierModel.name === ""
            || courierModel.last_name === ""
            || courierModel.email === ""
            || courierModel.phone === ""
            || courierModel.vehicle_id === -1
            || courierModel.address.city_id === ""
            || courierModel.address.county_id === ""
            || courierModel.address.text === ""
            || (!courierModel.image.squareTmp || courierModel.image.squareTmpErrors)
        ) {
            return false
        }
        return true
    }


    const handleCourierSubmit = (event) => {
        event.preventDefault();
        let formData = new FormData();
        if (courierModel.id) {
            //update
            if (!isValidPhoneNumber(courierModel.phone.countryCode + courierModel.phone.value)) {
                setFormErrors({ ...formErrors, phone: "Lütfen geçerli bir telefon numarası giriniz" })
            }
            else if (!courierModel.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                setFormErrors({ ...formErrors, email: "Lütfen geçerli bir email giriniz" })
            }
            else {


                for (var key in courierModel) {

                    if (key !== "image") {
                        //console.log(key, typeof courierModel[key]);
                        if (typeof courierModel[key] === 'object') {
                            if (key == "phone") {
                                formData.append(key, courierModel.phone.countryCode + courierModel.phone.value)
                            }
                            else {
                                console.log("dfs:" + JSON.stringify(courierModel[key]))
                                formData.append(key, JSON.stringify(courierModel[key]));
                            }

                        } else {
                            if (key !== "name" || key !== "last_name") {
                                formData.append(key, courierModel[key]);
                            }
                        }
                    }
                    else {
                        if (typeof courierModel.image.squareTmp !== 'string') {
                            formData.append('image', courierModel.image.squareTmp, "courier_" + courierModel.name + "_" + courierModel.last_name);
                        }

                    }
                }
                //dispatch(updateCourier(formData, history));
            }
        }

        else {
            //new courier
            if (!isValidPhoneNumber(courierModel.phone.countryCode + courierModel.phone.value)) {
                setFormErrors({ ...formErrors, phone: "Lütfen geçerli bir telefon numarası giriniz" })
            }
            else if (!courierModel.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
                setFormErrors({ ...formErrors, email: "Lütfen geçerli bir email giriniz" })
            }
            else {
                for (var key in courierModel) {

                    if (key !== "image") {
                        //console.log(key, typeof courierModel[key]);
                        if (typeof courierModel[key] === 'object') {
                            if (key == "phone") {
                                formData.append(key, courierModel.phone.countryCode + courierModel.phone.value)
                            }
                            else {
                                console.log("dfs:" + JSON.stringify(courierModel[key]))
                                formData.append(key, JSON.stringify(courierModel[key]));
                            }

                        } else {
                            formData.append(key, courierModel[key]);
                        }
                    }
                    else {
                        formData.append('image', courierModel.image.squareTmp, "courier_" + courierModel.name + "_" + courierModel.last_name);
                    }
                }
                //dispatch(createCourier(formData, history));
            }

        }
    }


    return (
        <Grid container justifyContent="center">
            {
                !loading || !vehicles || !Array.isArray(vehicles)
                    ?
                    <Grid item xs={12} md={10} lg={8}>
                        <Paper
                            style={{ width: "100%", height: "82.5vh", overflow: "hidden", position: "relative" }}>
                            <Grid container sx={{ bgcolor: 'primary.main' }}>
                                <Typography variant="h6" sx={{ margin: 2, fontWeight: "700", ...theme.styles.whiteTextShadow }}>
                                    {
                                        (courierModel.id
                                            ?
                                            courierModel.name
                                            :
                                            "Yeni Kurye"
                                        )
                                    }
                                </Typography>
                            </Grid>

                            <Grid container justifyContent="center"
                                style={{ overflowY: "scroll", height: "inherit" }}
                                sx={{
                                    pb: {
                                        xs: 20,
                                        md: 15
                                    },
                                    '&.MuiGrid-root::-webkit-scrollbar': {
                                        display: "none"
                                    }
                                }}
                            >

                                <Grid item xs={12} md={10} sx={{
                                    p: {
                                        xs: 2,
                                        md: 1
                                    }
                                }}>
                                    <Grid container>
                                        <Grid item xs={12} sx={{ p: 3, border: "2px dashed orange", borderRadius: 8, backgroundColor: "rgba(0,0,0,0.1)" }} style={{ display: "flex", justifyContent: "space-around" }}>
                                            {
                                                !courierModel.image.squareTmp
                                                    ?
                                                    <Dropzone
                                                        containerStyle={{ justifyContent: "center", alignItems: "center" }}
                                                        multiple={false}
                                                        fileDisplayContainer={false}
                                                        setValidFile={(file) => selectCourierImage(file)}
                                                        renderContent={(dragOver, dragEnter, dragLeave, fileDrop, fileInputClicked) => renderDropzoneContent(dragOver, dragEnter, dragLeave, fileDrop, fileInputClicked)}
                                                    />
                                                    :
                                                    <Paper style={{ display: "flex", flexDirection: "column", alignItems: "center", minWidth: 275, maxHeight: 200 }}>
                                                        <img src={typeof courierModel.image.squareTmp !== 'string' ? URL.createObjectURL(courierModel.image.squareTmp) : courierModel.image.squareTmp} style={{ maxWidth: "80%", maxHeight: "60%", marginTop: "1em", borderRadius: "50%" }} />
                                                        <Grid container justifyContent="center" sx={{ mt: 2 }}>
                                                            <Grid item xs={3} style={{ ...theme.styles.centerColContent }}>
                                                                <Button
                                                                    onClick={() => setCourierModel({ ...courierModel, image: { ...courierModel.image, squareTmp: null, squareTmpErrors: null } })}
                                                                    sx={{ p: 0 }}
                                                                    style={{ ...theme.styles.centerColContent, fontSize: 11 }}
                                                                >
                                                                    <DeleteForeverIcon color='primary' />
                                                                    <p style={{ margin: 0 }}>Görseli Sil</p>
                                                                </Button>
                                                            </Grid>
                                                            {
                                                                courierModel.image.squareTmpErrors
                                                                    ?
                                                                    <Grid item xs={3}>
                                                                        <Button onClick={() => setCropModalOpen(true)}
                                                                            sx={{ p: 0 }}
                                                                            style={{ ...theme.styles.centerColContent, fontSize: 11 }}
                                                                        >
                                                                            <CropIcon color='primary' />
                                                                            <p style={{ margin: 0 }}>Kırp</p>
                                                                        </Button>

                                                                    </Grid>
                                                                    :
                                                                    null
                                                            }
                                                        </Grid>
                                                    </Paper>
                                            }

                                        </Grid>

                                        <Grid item xs={12}>
                                            {
                                                !cropModalOpen && courierModel.image.squareTmpErrors
                                                    ?
                                                    <div style={{ marginTop: "1em", backgroundColor: "rgba(255,0,0,0.75)", borderRadius: 8 }}>
                                                        <Typography sx={{ p: 1, ...theme.styles.whiteTextShadow }}>{courierModel.image.squareTmpErrors}</Typography>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </Grid>
                                    </Grid>


                                    <Divider sx={{ my: 4, bgcolor: "primary.light" }} />


                                    <form noValidate onSubmit={(event) => handleCourierSubmit(event)} style={{ width: "100%" }}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} md={4}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        disabled={courierModel.id ? true : false}
                                                        fullWidth
                                                        variant="outlined"
                                                        id="name"
                                                        name="name"
                                                        label="Adı"
                                                        sx={styles.textField}
                                                        value={courierModel.name}
                                                        onChange={(event) => setCourierModel({
                                                            ...courierModel, name: event.target.value
                                                        })} />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} md={4}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        disabled={courierModel.id ? true : false}
                                                        fullWidth
                                                        variant="outlined"
                                                        id="last_name"
                                                        name="last_name"
                                                        label="Soyadı"
                                                        sx={styles.textField}
                                                        value={courierModel.last_name}
                                                        onChange={(event) => setCourierModel({
                                                            ...courierModel, last_name: event.target.value
                                                        })} />
                                                </FormControl>
                                            </Grid>


                                            <Grid item xs={12} md={4}>
                                                <FormControl variant="outlined" fullWidth>
                                                    <InputLabel id="status-label">Statü</InputLabel>
                                                    <Select
                                                        labelId="status-label"
                                                        id="status_id"
                                                        name="status_id"
                                                        value={courierModel.status}
                                                        onChange={(e) => setCourierModel({ ...courierModel, status: e.target.value })}
                                                        label={"Statü"}
                                                    >
                                                        {status_markup}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>



                                        <Divider sx={{ my: 4, bgcolor: "primary.light" }} />


                                        <Grid container spacing={1}>

                                            <Grid item xs={12} md={6}>
                                                <FormControl variant="outlined" fullWidth>
                                                    <TextField
                                                        id="email"
                                                        name="email"
                                                        type="email"
                                                        label="Email"
                                                        helperText={formErrors.email ? formErrors.email : null}
                                                        FormHelperTextProps={{ error: true }}
                                                        value={courierModel.email}
                                                        onChange={(e) => setCourierModel({ ...courierModel, email: e.target.value })} />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} md={6}>
                                                <FormControl variant="outlined" fullWidth>
                                                    <TextField
                                                        id="phone"
                                                        name="phone"
                                                        type="tel"
                                                        label="Telefon Numarası"
                                                        helperText={formErrors.phone ? formErrors.phone : null}
                                                        FormHelperTextProps={{ error: true }}
                                                        sx={styles.textField}
                                                        value={courierModel.phone.countryCode + courierModel.phone.value}
                                                        onChange={(e) => setCourierModel({
                                                            ...courierModel, phone: {
                                                                ...courierModel.phone,
                                                                value: e.target.value.substring(courierModel.phone.countryCode.length)
                                                            }
                                                        })}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>



                                        <Divider sx={{ my: 4, bgcolor: "primary.light" }} />


                                        <Grid container spacing={1}>
                                            <Grid item xs={12} md={4}>
                                                <FormControl variant="outlined" fullWidth>
                                                    <InputLabel id="city-label">Şehir</InputLabel>
                                                    <Select
                                                        labelId="city-label"
                                                        id="city_id"
                                                        name="city_id"
                                                        value={courierModel.address.city_id}
                                                        onChange={(e) => setCourierModel({ ...courierModel, address: { ...courierModel.address, city_id: e.target.value, county_id: "" } })}
                                                        label={"Şehir"}
                                                    >
                                                        {city_markup}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <FormControl variant="outlined" fullWidth>
                                                    <InputLabel id="county-label">İlçe</InputLabel>
                                                    <Select
                                                        disabled={courierModel.address.city_id == ""}
                                                        labelId="county-label"
                                                        id="county_id"
                                                        name="county_id"
                                                        value={courierModel.address.county_id}
                                                        onChange={(e) => setCourierModel({ ...courierModel, address: { ...courierModel.address, county_id: e.target.value } })}
                                                        label={"İlçe"}
                                                    >
                                                        {county_markup}
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} md={4}>
                                                <FormControl variant="outlined" fullWidth>
                                                    <InputLabel id="vehicle-label">Araç</InputLabel>
                                                    <Select
                                                        disabled={courierModel.address.county_id == ""}
                                                        labelId="vehicle-label"
                                                        id="vehicle_id"
                                                        name="vehicle_id"
                                                        value={courierModel.vehicle_id}
                                                        onChange={(e) => setCourierModel({ ...courierModel, vehicle_id: e.target.value })}
                                                        label={"Araç"}
                                                    >
                                                        {vehicle_markup}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>



                                        <Divider sx={{ my: 4, bgcolor: "primary.light" }} />


                                        <Grid container>
                                            <Grid item xs={12} md={8}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        variant="outlined"
                                                        id="address"
                                                        name="address"
                                                        type="text"
                                                        label="Açık Adres"
                                                        //helperText="mahalle, cadde, site, sokak etc."
                                                        multiline
                                                        rows={4}
                                                        sx={styles.textField}
                                                        value={courierModel.address.text}
                                                        onChange={(e) => setCourierModel({ ...courierModel, address: { ...courierModel.address, text: e.target.value } })}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>


                                        <Divider sx={{ my: 4, bgcolor: "primary.light" }} />

                                        <Grid container justifyContent="center" style={{ position: "absolute", left: 0, bottom: 0 }}>
                                            <Button
                                                fullWidth
                                                type="submit"
                                                variant="contained"
                                                color="info"
                                                sx={{ ...styles.confirmBtn }}
                                                disabled={!checkValid()}
                                            >
                                                {courierModel.id ? "Güncelle" : "Ekle"}
                                            </Button>
                                        </Grid>

                                    </form>

                                </Grid>
                            </Grid>
                        </Paper>


                        <SimpleModal
                            open={cropModalOpen}
                            modalContainerStyle={{ backgroundColor: "#fff", overflowY: "scroll" }}
                            fullScreen={true}
                            fullScreenContent={<MyCropComponent
                                src={courierModel.image.squareTmp ? typeof courierModel.image.squareTmp !== 'string' ? URL.createObjectURL(courierModel.image.squareTmp) : courierModel.image.squareTmp : null}
                                type={0}
                                toggleCropModal={() => setCropModalOpen(!cropModalOpen)}
                                setCroppedImage={(blob) => setCroppedImage(blob)}
                            />}
                        />

                    </Grid>
                    :
                    <Loading />
            }
        </Grid >

    )

}

export default CourierForm;

