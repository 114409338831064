import axiosInstance from '../redux/actions/axiosInstance'
import React, { Suspense, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import Layout from '../components/Layout'
import Sectiontitle from '../components/Sectiontitle'
import Service from '../components/Service'
import Spinner from '../components/Spinner'
import ProcessStepper from '../components/ProcessStepper'

const WhatWeDo = ({ lightMode }) => {
  const [steps, setSteps] = useState([])
  const [services, setServices] = useState([])
  const [techs, setTechs] = useState([])

  useEffect(() => {
    axiosInstance.get('/api/steps').then((response) => {
      setSteps(response.data)
    })
    axiosInstance.get('/api/services').then((response) => {
      setServices(response.data)
    })
    axiosInstance.get('/api/techs').then((response) => {
      setTechs(response.data)
    })
  }, [])

  return (
    <Layout lightMode={lightMode}>
      <Helmet>
        <title>About - Cogito Technology</title>
        <meta name="description" content="Cogito Technology About Page" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
      </Helmet>
      <Suspense fallback={<Spinner />}>
        <div className="mi-service-area mi-section mi-padding-top">
          <div className="container">
            <Sectiontitle title="Services" />
            <div className="mi-service-wrapper">
              <div className="row mt-30-reverse justify-content-around ">
                {services.map((service) => (
                  <div key={service.title} className="col-md-6 col-12 mt-30">
                    <Service content={service} />
                  </div>
                ))}
              </div>
            </div>

            <div className="my-5"></div>

            <Sectiontitle title="Our Workflow" />
            <div className="mi-service-wrapper">
              {steps.length > 0 ? <ProcessStepper steps={steps} /> : null}
            </div>

            <div className="my-5"></div>
            
            <Sectiontitle title="Technologies we use" />
            <div className="row gy-4 mx-2 my-5 py-3 justify-content-center align-items-center mi-tech-row">
              {techs.map((tech) => (
                <div
                  key={tech.name}
                  className="col-2 d-flex justify-content-center"
                >
                  <img
                    src={tech.img}
                    style={{
                      maxHeight: 40,
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Suspense>
    </Layout>
  )
}

export default WhatWeDo
