import {UPDATE_WEBSOCKET} from '../types';
import axiosInstance from './axiosInstance';


/*
export const openSocket = (namespace) => {
    console.log("opening socket..");
    return {
        type: OPEN_WEBSOCKET,
        payload: { namespace }
    }
}
*/

export const updateSocket = (socket) => {
    console.log("updating socket..")
    return {
        type: UPDATE_WEBSOCKET,
        payload: {
            socket
        }
    }
}