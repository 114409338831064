import FsLightbox from 'fslightbox-react'
import 'photoswipe/dist/photoswipe.css'
import { Gallery, Item, useGallery } from 'react-photoswipe-gallery'
import React, { useState } from 'react'
//import * as Icon from 'react-feather'
//import ProgressiveImage from 'react-progressive-image'

import ZoomInIcon from '@mui/icons-material/ZoomIn'

const smallItemStyles = {
  cursor: 'pointer',
  objectFit: 'contain',
  //width: '100%',
  //height: '100%',
  //maxHeight: '300px',
  //position: 'absolute',
  //top: 0,
  //left: 0,
  //right: 0,
  //bottom: 0,
  //transform: "translateX(50%)",
}

const uiElements = [
  {
    name: 'bulletsIndicator',
    order: 9,
    isButton: false,
    appendTo: 'wrapper',
    onInit: (el, pswpInstance) => {
      let prevIndex = -1
      const thumbnails = []

      el.style.position = 'absolute'
      el.style.bottom = '20px'
      el.style.left = '10px'
      el.style.right = '0'
      el.style.display = 'grid'
      el.style.gridGap = '10px'
      el.style.gridTemplateColumns = 'repeat(auto-fit, 40px)'
      el.style.gridTemplateRows = 'repeat(auto-fit, 40px)'
      el.style.justifyContent = 'center'

      const dataSource = pswpInstance.options.dataSource

      for (let i = 0; i < dataSource.length; i++) {
        const slideData = dataSource[i]

        const thumbnail = document.createElement('div')
        thumbnail.style.transition = 'transform 0.15s ease-in'
        thumbnail.style.opacity = '0.6'
        thumbnail.style.cursor = 'pointer'
        thumbnail.onclick = (e) => {
          const target = e.target
          const thumbnailEl =
            target.tagName === 'IMG' ? target.parentElement : e.target
          pswpInstance.goTo(thumbnails.indexOf(thumbnailEl))
        }

        const thumbnailImage = document.createElement('img')
        //console.log("slideData.msrc: " + slideData.src)
        thumbnailImage.setAttribute('src', slideData.src)
        thumbnailImage.style.width = '100%'
        thumbnailImage.style.height = '100%'
        thumbnailImage.style.objectFit = 'cover'

        thumbnail.appendChild(thumbnailImage)

        el.appendChild(thumbnail)

        thumbnails.push(thumbnail)
      }

      pswpInstance.on('change', () => {
        if (prevIndex >= 0) {
          const prevThumbnail = thumbnails[prevIndex]
          prevThumbnail.style.opacity = '0.6'
          prevThumbnail.style.cursor = 'pointer'
          prevThumbnail.style.transform = 'scale(1)'
        }

        const currentThumbnail = thumbnails[pswpInstance.currIndex]
        currentThumbnail.style.opacity = '1'
        currentThumbnail.style.cursor = 'unset'
        currentThumbnail.style.transform = 'scale(1.2)'

        prevIndex = pswpInstance.currIndex
      })
    },
  },
]

const PortfolioItem = (props) => {
  //const { open } = useGallery()
  const [toggler, setToggler] = useState(false)
  const {
    title,
    subtitle,
    imageUrl,
    largeImageUrl,
    url,
    width,
    height,
  } = props.content

  const handleToggler = (value) => {
    setToggler(value)
  }

  return (
    <div className="mi-portfolio mi-portfolio-visible">
      {
        /*
      !largeImageUrl ? null : (
        <FsLightbox toggler={toggler} sources={largeImageUrl} showThumbsOnMount={true} />
      )
      */
        !largeImageUrl ? null : (
          <Gallery uiElements={uiElements}>
            <div
            className="mi-portfolio-gallery"
            >
              {largeImageUrl.map((uri, index) => (
                <Item
                  key={uri}
                  //content={index > 0 ? null : <img src={uri} />}
                  original={uri}
                  width={width}
                  height={height}
                >
                  {({ ref, open }) => (
                    <img
                      ref={ref}
                      style={smallItemStyles}
                      //width={index == 0 ? 300 : 0}
                      height={index == 0 ? 150 : 0}
                      onClick={open}
                      src={uri}
                    />
                  )}
                </Item>
              ))}
            </div>
          </Gallery>
        )
      }

      <h5>{title}</h5>
      {/*subtitle ? <h6>{subtitle}</h6> : null*/}
    </div>
  )
}

export default PortfolioItem
