export default {

    /*
    typography: {
        fontFamily: [
            'GothamRoundedBook',
            'Roboto',
        ].join(','),
        button: {
            fontFamily: '"GothamRoundedBook","Montserrat", Roboto',
            textTransform: 'none'
        }
    },
    */
    typography: {
        fontFamily: [
            'Nunito',
            'Roboto',
        ].join(','),
    },
    overrides: {
        // Style sheet name ⚛️
        /*
        MuiCssBaseline: {
            '@global': {
                '@font-face': [gotham, gotham_rounded, montserrat, langar, lobster],
            },
        },
        */
        MuiAccordionSummary: {
            // Name of the rule
            content: {
                // Some CSS
                margin: 0,
            },
        },
        MuiSwitch: {
            "colorSecondary": {
                "&$checked": {
                    "color": "#008DF3",
                    "& + $track": {
                        "backgroundColor": "#008DF3"
                    }
                },

            }
        }
    },
    palette: {
        primary: {
            light: 'rgba(41, 37, 37, 0.15)',
            main: "#292525",
            dark: '#171616',
            contrastText: '#e8eaf6',
        },
        secondary: {
            light: '#5eb8ff',
            main: "#f39200",
            dark: '#005b9f',
            contrastText: '#e8eaf6',
        },
        success: {
            main: "#3EB216",
            light: 'rgba(62, 178, 22, 0.75)',
            dark: '#005b9f',
            contrastText: '#e8eaf6',
            //contrastText: '#fff',
        },
        info: {
            main: "#008DF3",
            light: "rgba(0, 141, 243, 0.15)"
        },
        warning: {
            main: "#f99e1e"
        },
        d1: {
            main: "#e50046"
        },

        d2: {
            main: "#f39200"
        },
        appBar: {
            main: "rgb(91,63,91)",
            transparent: "rgb(134 108 134 / 49%)"
        },

        danger: {
            main: '#db270b',
            light: "rgba(219, 39, 11, 0.25)"
        },

        adminDrawer: {
            sidebar: {
                main: 'rgb(22, 25, 31)',
                menuItem: 'rgba(235, 223, 237, 0.6)'
            },
            toolbar: {
                main: 'rgba(209, 6, 30, 0.8)'
            }
        },
        providerDrawer: {
            sidebar: {
                main: '#54351f42',
                menuItem: 'rgb(51 60 28 / 40%)'
            },
            toolbar: {
                main: 'rgb(242 13 84)'
            }
        },
        white: {
            main: "#fff"
        },
        green: {
            dark: '#11783d',
            light: '#1ddb33'
        },
        red: {
            main: '#db270b'
        },
        blue: {
            main: '#4877f7'
        },
        purple: {
            main: '#bc28f7'
        },
        brown: {
            main: '#b57141'
        },
        wheat: {
            main: '#f5deb3'
        },
        yellow: {
            main: '#eba207'
        },
        orange: {
            main: '#ff801f'
        },
        grey: {
            main: '#ddd'
        }
    },
    styles: {
        centerColContent: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
        },
        centerColContentHorizontal: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        centerColContentVertical: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
        },
        centerRowContentVertical: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        },
        whiteTextShadow:
        {
            color: '#fff',
            textShadow: '0.05em 0.05em rgba(0, 0, 0, 0.75)',
        },
        darkTextShadow:
        {
            color: '#000',
            textShadow: '0.05em 0.05em rgba(255, 255, 255, 0.75)',
        },
    },
}