import React, { useEffect, useRef, useState } from 'react';

import Moment from 'react-moment';
import 'moment/locale/tr';
import 'moment-timezone';

import { getChatRooms, getRoomConversation, setSelectedRoomId, sendMessageToRoom, markMessagesAsRead } from '../redux/actions';

// MUI stuff
import {
    Grid, AppBar, CssBaseline, Accordion, AccordionSummary, AccordionDetails,
    Drawer, Button, IconButton, List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography, Box, Divider
} from '@mui/material';

import { useTheme } from '@mui/material/styles';

import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import CakeIcon from '@mui/icons-material/Cake';
import StoreIcon from '@mui/icons-material/Store';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import CelebrationIcon from '@mui/icons-material/Celebration';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';

import { useSelector, useDispatch } from 'react-redux'
import { Link, useRouteMatch, Switch } from 'react-router-dom';

// Pages
import Admin from '../pages/admin/admin';
import Courier from '../pages/admin/Courier';
import CourierForm from '../pages/admin/CourierForm';

// Components
import AuthRoute from './AuthRoute';

// images
import DEFAULT_USER from '../images/default_user.png';




const drawerWidth = 240;

const styles = {
    root: {
        height: "100%",
        display: 'flex',
    },
    appLogo: {
        width: "100%",
        height: "auto",
        //margin: '20px auto 20px auto'
    },
    drawerPaper: {
        '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            background: "#141E30",  /* fallback for old browsers */
            background: "-webkit-linear-gradient(to bottom, #243B55, #141E30)",  /* Chrome 10-25, Safari 5.1-6 */
            background: "linear-gradient(to bottom, #243B55, #141E30)", /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        },
    },
    sidebarItem: {
        width: '100%',
        backgroundColor: "transparent",
        '&.MuiAccordion-root': {
            boxShadow: 0,
            '&::before': {
                display: "none"
            }
        }
        /*
        background: "rgb(215,215,187)",
        background: "-moz-linear-gradient(180deg, rgba(215,215,187,1) 67%, rgba(157,157,136,1) 100%)",
        background: "-webkit-linear-gradient(180deg, rgba(215,215,187,1) 67%, rgba(157,157,136,1) 100%)",
        background: "linear-gradient(180deg, rgba(215,215,187,1) 67%, rgba(157,157,136,1) 100%)"
        */
    },
    expanded: {
        '& .MuiAccordionSummary-content': {
            margin: 0,
            alignItems: "center"
        },
        '&.Mui-expanded': {
            minHeight: "unset",
            maxHeight: 58,
        }
    },
    heading: {
        color: "#a9baca"
    },
    totalUnreadBadge: {
        '& .MuiBadge-badge': {
            backgroundColor: "#4aa1f3",
            color: "white"
        }
    },
    unreadBadge: {
        '& .MuiBadge-badge': {
            right: "-12px",
            top: "12px",
            backgroundColor: "#4aa1f3",
            color: "white"
        }
    },
    logOutBtn: {
        width: "100%"
        //color: "grey"
    },
    msgInput: {
        borderRadius: "21px"
    },
    chatBubbleMeStipe: {
        right: "-8px",
        color: "#56CCF2",
        position: "absolute"
    },

    chatBubbleMeMain: {
        background: "#56CCF2",
        /* fallback for old browsers */
        background: "-webkit-linear-gradient(to right, #2F80ED, #56CCF2)",
        /* Chrome 10-25, Safari 5.1-6 */
        background: "linear-gradient(to right, #2F80ED, #56CCF2)",
        /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        borderRadius: "7.5px",
        borderTopRightRadius: 0,
        color: "#fff",
    },

    chatBubbleYouStipe: {
        left: "-8px",
        color: "#FFFFFF",
        position: "absolute"
    },

    chatBubbleYouMain: {
        background: "#ECE9E6",
        /* fallback for old browsers */
        background: "-webkit-linear-gradient(to right, #FFFFFF, #ECE9E6)",
        /* Chrome 10-25, Safari 5.1-6 */
        background: "linear-gradient(to right, #FFFFFF, #ECE9E6)",
        /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        borderRadius: "7.5px",
        borderTopLeftRadius: 0
    },
    whiteTextShadow: {
        color: 'white',
        textShadow: '0.075em 0.075em rgba(0, 0, 0, 0.75)',
    },
    darkTextShadow: {
        color: 'black',
        textShadow: '0.05em 0.05em rgba(255, 255, 255, 0.75)',
    },
};

const AdminDrawer = (props) => {
    //console.log("ADMIN PROPS: " + JSON.stringify(props));
    const { logOut } = props;
    const theme = useTheme();
    const dispatch = useDispatch()
    let { path, url } = useRouteMatch();

    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            <Toolbar sx={{ justifyContent: "center", alignItems: "center" }}>
                <Button component={Link} to={`${url}`}>
                    <img src="" alt="logo" style={styles.appLogo} />
                </Button>
            </Toolbar>

            <Accordion sx={styles.sidebarItem}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: "#a9baca" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={styles.expanded}
                >
                    <Typography sx={styles.heading}>Admin</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <List>
                        {[
                            { text: 'Araç', icon: <AirportShuttleIcon color="white" />, link: "vehicles" },
                            { text: 'Destek Ekibi', icon: <SupportAgentIcon color="white" />, link: "supports" },
                            { text: 'Kurye', icon: <PersonAddIcon color="white" />, link: "couriers" },
                            { text: 'Ürün', icon: <CelebrationIcon color="white" />, link: "products" },
                            { text: 'Paket Ürün', icon: <CelebrationIcon color="white" />, link: "product_packages" },
                            { text: 'Bildirim', icon: <NotificationsIcon color="white" /> },
                            { text: 'Adminler', icon: <PersonAddIcon color="white" /> },
                            { text: 'Rapor', icon: <AssessmentIcon color="white" /> }].map((item, index) => (
                                <ListItem button key={item.text} component={Link} to={`${url}/${item.link}`}>
                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                    <ListItemText sx={{ color: "#a9baca", '& .MuiListItemText-primary': { fontSize: "0.85rem" } }} primary={item.text} />
                                </ListItem>
                            ))}
                    </List>
                </AccordionDetails>
            </Accordion>

            <Accordion sx={styles.sidebarItem}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: "#a9baca" }} />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    sx={styles.expanded}
                >
                    <Typography sx={styles.heading}>Pastane</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <List>
                        {[
                            { text: 'Pastaneler', icon: <StoreIcon color="white" />, link: "providers" },
                            { text: 'Onay Bekleyen Ürünler', icon: <CakeIcon color="white" />, link: "pending_products" },
                            { text: 'Onay Bekleyenler Görseller', icon: <StoreIcon color="white" />, link: "pending_visuals" }
                        ].map((item, index) => (
                            <ListItem button key={item.text} component={Link} to={`${url}/${item.link}`}>
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText sx={{ color: "#a9baca", '& .MuiListItemText-primary': { fontSize: "0.85rem" } }} primary={item.text}>
                                </ListItemText>
                            </ListItem>
                        ))}
                    </List>
                </AccordionDetails>
            </Accordion>

            <Grid container sx={{ position: "absolute", left: 0, bottom: 0, right: 0 }}>
                <Button onClick={logOut} size="small" color={"primary"} sx={styles.logOutBtn} variant="contained">
                    <Typography variant="button">Çıkış</Typography>
                    <ExitToAppIcon />
                </Button>
            </Grid>
        </div >
    );

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>

                    <Grid container justifyContent="space-between">
                        <Grid item xs={3}>
                            <Typography variant="h6" sx={theme.styles.whiteTextShadow} noWrap>
                                Yönetici Paneli
                            </Typography>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>

            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="admin drawer"
            >
                <Drawer
                    variant="temporary"
                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        ...styles.drawerPaper
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        ...styles.drawerPaper
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>

            <Box component="main" sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` }, minHeight: "100vh", backgroundColor: "#ddd" }}>
                <Toolbar />
                <Switch>
                    <AuthRoute exact path={path} component={Admin} />
                    <AuthRoute path={`${path}/couriers`} component={Courier} />
                    <AuthRoute path={`${path}/courier`} component={CourierForm} />
                </Switch>
            </Box>
        </Box>
    );
}


export default AdminDrawer;
