import Mock from '../mock'
import LOGO_DARK from '../../images/logo_dark.png'
import LOGO_WHITE from '../../images/logo_white.png'

import FATBALL_0 from '../../images/portfolio/FatBall/main_char.jpg'
import FATBALL_1 from '../../images/portfolio/FatBall/app_store.jpg'
import FATBALL_2 from '../../images/portfolio/FatBall/char_shield.jpg'
import FATBALL_3 from '../../images/portfolio/FatBall/characters.jpg'
import FATBALL_4 from '../../images/portfolio/FatBall/gameplay_1.jpg'
import FATBALL_5 from '../../images/portfolio/FatBall/hazard.jpg'
import FATBALL_6 from '../../images/portfolio/FatBall/pitbone.jpg'

import IGOR_0 from '../../images/portfolio/Igor/card.jpg'
import IGOR_1 from '../../images/portfolio/Igor/cards.jpg'
import IGOR_2 from '../../images/portfolio/Igor/customers.jpg'
import IGOR_3 from '../../images/portfolio/Igor/expenses.jpg'
import IGOR_4 from '../../images/portfolio/Igor/login.jpg'
import IGOR_5 from '../../images/portfolio/Igor/register.jpg'
import IGOR_6 from '../../images/portfolio/Igor/register_2.jpg'

import PE_WEB_0 from '../../images/portfolio/PersonalExercise/web/home.jpg'
import PE_WEB_1 from '../../images/portfolio/PersonalExercise/web/new_exercise.jpg'
import PE_WEB_2 from '../../images/portfolio/PersonalExercise/web/exercise_detail.jpg'
import PE_WEB_3 from '../../images/portfolio/PersonalExercise/web/new_training.jpg'
import PE_WEB_4 from '../../images/portfolio/PersonalExercise/web/trainings.jpg'
import PE_WEB_5 from '../../images/portfolio/PersonalExercise/web/program_week.jpg'
import PE_WEB_6 from '../../images/portfolio/PersonalExercise/web/program_calendar.jpg'
import PE_WEB_7 from '../../images/portfolio/PersonalExercise/web/program_slider.jpg'
import PE_WEB_8 from '../../images/portfolio/PersonalExercise/web/members.jpg'
import PE_WEB_9 from '../../images/portfolio/PersonalExercise/web/member_detail.jpg'
import PE_WEB_10 from '../../images/portfolio/PersonalExercise/web/new_member.jpg'
import PE_WEB_11 from '../../images/portfolio/PersonalExercise/web/login.jpg'

import PE_MOBILE_0 from '../../images/portfolio/PersonalExercise/mobile/home.jpg'
import PE_MOBILE_1 from '../../images/portfolio/PersonalExercise/mobile/calendar.jpg'
import PE_MOBILE_2 from '../../images/portfolio/PersonalExercise/mobile/training.jpg'
import PE_MOBILE_3 from '../../images/portfolio/PersonalExercise/mobile/plan.jpg'
import PE_MOBILE_4 from '../../images/portfolio/PersonalExercise/mobile/exercise_detail.jpg'
import PE_MOBILE_5 from '../../images/portfolio/PersonalExercise/mobile/exercise_video.jpg'
import PE_MOBILE_6 from '../../images/portfolio/PersonalExercise/mobile/profile_2.jpg'

import PASTASEPETI_ADMIN_0 from '../../images/portfolio/PastaSepeti/admin/providers.png'
import PASTASEPETI_ADMIN_1 from '../../images/portfolio/PastaSepeti/admin/add_provider.png'
import PASTASEPETI_ADMIN_2 from '../../images/portfolio/PastaSepeti/admin/products.png'
import PASTASEPETI_ADMIN_3 from '../../images/portfolio/PastaSepeti/admin/add_product.png'
import PASTASEPETI_ADMIN_4 from '../../images/portfolio/PastaSepeti/admin/add_courier.png'
import PASTASEPETI_ADMIN_5 from '../../images/portfolio/PastaSepeti/admin/add_vehicle.png'

import PASTASEPETI_PROVIDER_0 from '../../images/portfolio/PastaSepeti/provider/login.png'
import PASTASEPETI_PROVIDER_1 from '../../images/portfolio/PastaSepeti/provider/home.png'
import PASTASEPETI_PROVIDER_2 from '../../images/portfolio/PastaSepeti/provider/add_product.png'
import PASTASEPETI_PROVIDER_3 from '../../images/portfolio/PastaSepeti/provider/add_product_1.png'
import PASTASEPETI_PROVIDER_4 from '../../images/portfolio/PastaSepeti/provider/menu.png'
import PASTASEPETI_PROVIDER_5 from '../../images/portfolio/PastaSepeti/provider/menu_2.png'
import PASTASEPETI_PROVIDER_6 from '../../images/portfolio/PastaSepeti/provider/orders.png'

import PASTASEPETI_SUPPORT_0 from '../../images/portfolio/PastaSepeti/support/home.png'

import PASTASEPETI_MOBILE_0 from '../../images/portfolio/PastaSepeti/mobile/login.png'
import PASTASEPETI_MOBILE_1 from '../../images/portfolio/PastaSepeti/mobile/welcome_modal.png'
import PASTASEPETI_MOBILE_2 from '../../images/portfolio/PastaSepeti/mobile/add_address.png'
import PASTASEPETI_MOBILE_3 from '../../images/portfolio/PastaSepeti/mobile/add_address_1.png'
import PASTASEPETI_MOBILE_4 from '../../images/portfolio/PastaSepeti/mobile/add_address_2.png'
import PASTASEPETI_MOBILE_5 from '../../images/portfolio/PastaSepeti/mobile/add_address_3.png'
import PASTASEPETI_MOBILE_6 from '../../images/portfolio/PastaSepeti/mobile/search.png'
import PASTASEPETI_MOBILE_7 from '../../images/portfolio/PastaSepeti/mobile/search_1.png'
import PASTASEPETI_MOBILE_8 from '../../images/portfolio/PastaSepeti/mobile/product_detail.png'
import PASTASEPETI_MOBILE_9 from '../../images/portfolio/PastaSepeti/mobile/product_detail_1.png'
import PASTASEPETI_MOBILE_10 from '../../images/portfolio/PastaSepeti/mobile/order_detail.png'
import PASTASEPETI_MOBILE_11 from '../../images/portfolio/PastaSepeti/mobile/basket.png'
import PASTASEPETI_MOBILE_12 from '../../images/portfolio/PastaSepeti/mobile/delivery_detail.png'
import PASTASEPETI_MOBILE_13 from '../../images/portfolio/PastaSepeti/mobile/payment.png'
import PASTASEPETI_MOBILE_14 from '../../images/portfolio/PastaSepeti/mobile/order_received.png'
import PASTASEPETI_MOBILE_15 from '../../images/portfolio/PastaSepeti/mobile/design_order.png'
import PASTASEPETI_MOBILE_16 from '../../images/portfolio/PastaSepeti/mobile/design_order_1.png'
import PASTASEPETI_MOBILE_17 from '../../images/portfolio/PastaSepeti/mobile/design_order_2.png'
import PASTASEPETI_MOBILE_18 from '../../images/portfolio/PastaSepeti/mobile/design_order_3.png'

import STEP_0 from '../../images/steps/meeting.jpg'

import TECH_0 from '../../images/techs/react_native.png'
import TECH_1 from '../../images/techs/react.png'
import TECH_2 from '../../images/techs/python.png'
import TECH_3 from '../../images/techs/flask.png'
import TECH_4 from '../../images/techs/socket_io.png'
import TECH_5 from '../../images/techs/postgres.png'
import TECH_6 from '../../images/techs/mysql.png'
import TECH_7 from '../../images/techs/rabbitmq.png'
import TECH_8 from '../../images/techs/aws.png'
import TECH_9 from '../../images/techs/kong.png'



import {
  Groups3,
  Psychology,
  DesignServices,
  Api,
  BugReport,
  School,
  SupportAgent,
} from '@mui/icons-material'

const database = {
  information: {
    name: 'Cogito Technology',
    aboutContent:
      'Here we start to work first by understanding you and the requirements of the job well to be able to deliver the most healthy and satisfying solutions.',
    age: 24,
    phone: '',
    nationality: 'American',
    language: 'English, French',
    email: '',
    address: '121 King Street, Melbourne, Australia',
    freelanceStatus: 'Available',
    socialLinks: {
      facebook: 'https://facebook.com',
      twitter: 'https://twitter.com',
      pinterest: '',
      behance: '',
      linkedin: '',
      dribbble: '',
      github: 'https://github.com',
    },
    brandImageDark: LOGO_DARK,
    brandImageWhite: LOGO_WHITE,
    aboutImage: LOGO_WHITE,
    aboutImageLg: LOGO_WHITE,
    cvfile: '/files/empty.pdf',
  },
  steps: [
    {
      title: 'First Meeting',
      name: 'diversity_3',
      details:'First and one of the most important steps of all, we are going to listen to every aspect of your project in details',
      image: STEP_0
    },
    {
      title: 'Analysis',
      name: 'psychology',
      details:
        'Based on the first meeting notes, we will analyze the project and its requirements',
    },
    {
      title: 'Design',
      name: 'view_quilt',
      details:
        "If you don't have a ready design or template in your hands. We are going to do our best to introduce you to talented UI/UX designers",
    },
    {
      title: 'Software',
      name: 'api',
      details:
        'Time to do what we do best. We are starting to code your project with the most suitable and convenient technologies to deliver a healthy product',
    },
    {
      title: 'Test',
      name: 'bug_report',
      details:
        'Now we are so close to the final, while testing the project to work perfectly with every functionality it has, you will also have a chance to test it on your own with the demos',
    },
    {
      title: 'Delivery and Training',
      name: 'school',
      details:
        "Congrats, you have a newborn project with every requirement you asked for. Now it's time to teach you how to play with it",
    },
    {
      title: 'Support',
      name: 'support_agent',
      details:
        "Maybe the most neglected part in our sector. People think that once their application goes live, it will continue to work without depending on any updates forever and be errorless. Actually, that is not the case for most of the projects. But don't worry, we are here to support you with our best",
    }
  ],
  services: [
    {
      title: 'Mobile Application Development',
      icon: 'phone_iphone',
      bgColor: '#ff6600',
      details:
        'We offer a broad range of React native, Flutter and Native development services for several industry verticals, including, but not limited to, healthcare, travel, e-learning, social media, entertainment, retail, gaming and lifestyle.',
    },
    {
      title: 'Web Application Development',
      icon: 'desktop_windows',
      bgColor: '#3e3c48',
      details:
        'Today, the ideal web site at the core should be based on stable code to assure accessibility, functionality and longevity to give users the best possible experience, as well as making it easier for web masters to manage it.',
    },
    {
      title: 'Software Development and Consultancy',
      icon: 'terminal',
      bgColor: '#05c6dd',
      details:
        'Today, the ideal web site at the core should be based on stable code to assure accessibility, functionality and longevity to give users the best possible experience, as well as making it easier for web masters to manage it.',
    },
    {
      title: 'E-commerce Development and Consultancy',
      icon: 'shopping_cart',
      bgColor: '#5176ba',
      details:
        'Based on our real-world omnichannel retail experience and high expertise in digital, we provide end-to-end digital retail consultancy, and outsourced digital marketing/e-commerce services to maximise the potential of our clients’ digital stores.',
    },
  ],
  technologies: [
    {
      title: 'React Native',
      img: TECH_0,
    },
    {
      title: 'React',
      img: TECH_1,
    },
    {
      title: 'Python',
      img: TECH_2,
    },
    {
      title: 'Flask',
      img: TECH_3,
    },
    {
      title: 'Socket.io',
      img: TECH_4,
    },

    {
      title: 'PostgreSQL',
      img: TECH_5,
    },
    {
      title: 'MySQL',
      img: TECH_6,
    },
    {
      title: 'RabbitMQ',
      img: TECH_7,
    },
    {
      title: 'aws',
      img: TECH_8,
    },
    {
      title: 'Kong',
      img: TECH_9,
    },
  ],
  portfolios: [
    {
      id: 1,
      title: 'Pastasepeti Mobile',
      subtitle: 'Clip sticker mockup design.',
      imageUrl: PASTASEPETI_MOBILE_0,
      largeImageUrl: [
        PASTASEPETI_MOBILE_0,
        PASTASEPETI_MOBILE_1,
        PASTASEPETI_MOBILE_2,
        PASTASEPETI_MOBILE_3,
        PASTASEPETI_MOBILE_4,
        PASTASEPETI_MOBILE_5,
        PASTASEPETI_MOBILE_6,
        PASTASEPETI_MOBILE_7,
        PASTASEPETI_MOBILE_8,
        PASTASEPETI_MOBILE_9,
        PASTASEPETI_MOBILE_10,
        PASTASEPETI_MOBILE_11,
        PASTASEPETI_MOBILE_12,
        PASTASEPETI_MOBILE_13,
        PASTASEPETI_MOBILE_14,
        PASTASEPETI_MOBILE_15,
        PASTASEPETI_MOBILE_16,
        PASTASEPETI_MOBILE_17,
        PASTASEPETI_MOBILE_18,
      ],
      width: 375,
      height: 806,
    },

    {
      id: 2,
      title: 'Pastasepeti Admin Panel',
      subtitle: 'Mug with awesome style',
      imageUrl: PASTASEPETI_ADMIN_0,
      largeImageUrl: [
        PASTASEPETI_ADMIN_0,
        PASTASEPETI_ADMIN_1,
        PASTASEPETI_ADMIN_2,
        PASTASEPETI_ADMIN_3,
        PASTASEPETI_ADMIN_4,
        PASTASEPETI_ADMIN_5,
      ],
      width: 1920,
      height: 940,
    },

    {
      id: 3,
      title: 'Pastasepeti Provider Panel',
      subtitle: 'Free pendrive mockup design.',
      imageUrl: PASTASEPETI_PROVIDER_0,
      largeImageUrl: [
        PASTASEPETI_PROVIDER_0,
        PASTASEPETI_PROVIDER_1,
        PASTASEPETI_PROVIDER_2,
        PASTASEPETI_PROVIDER_3,
        PASTASEPETI_PROVIDER_4,
        PASTASEPETI_PROVIDER_5,
        PASTASEPETI_PROVIDER_6,
      ],
      width: 1920,
      height: 940,
    },
    {
      id: 4,
      title: 'Pastasepeti Support Panel',
      subtitle: 'Pendrive with great design & flexible.',
      imageUrl: PASTASEPETI_SUPPORT_0,
      largeImageUrl: [PASTASEPETI_SUPPORT_0],
      width: 1920,
      height: 940,
    },

    {
      id: 5,
      title: 'PersonalExercise Mobile',
      subtitle: 'A pen holder with beautiful design.',
      imageUrl: PE_MOBILE_0,
      largeImageUrl: [
        PE_MOBILE_0,
        PE_MOBILE_1,
        PE_MOBILE_2,
        PE_MOBILE_3,
        PE_MOBILE_4,
        PE_MOBILE_5,
        PE_MOBILE_6,
      ],
      width: 1000,
      height: 750,
    },

    {
      id: 6,
      title: 'PersonalExercise Web',
      subtitle: 'Beautiful mug with logo.',
      imageUrl: PE_WEB_0,
      largeImageUrl: [
        PE_WEB_0,
        PE_WEB_1,
        PE_WEB_2,
        PE_WEB_3,
        PE_WEB_4,
        PE_WEB_5,
        PE_WEB_6,
        PE_WEB_7,
        PE_WEB_8,
        PE_WEB_9,
        PE_WEB_10,
        PE_WEB_11,
      ],
      width: 1000,
      height: 563,
    },
    {
      id: 7,
      title: 'FatBall',
      subtitle: 'Eat Healthy Live Long',
      imageUrl: FATBALL_0,
      largeImageUrl: [
        FATBALL_0,
        FATBALL_1,
        FATBALL_2,
        FATBALL_3,
        FATBALL_4,
        FATBALL_5,
        FATBALL_6,
      ],
      width: 1000,
      height: 750,
    },
    {
      id: 8,
      title: 'Igor',
      subtitle: 'Awesome coffee mug design.',
      imageUrl: IGOR_0,
      largeImageUrl: [IGOR_0, IGOR_1, IGOR_2, IGOR_3, IGOR_4, IGOR_5, IGOR_6],
      width: 994,
      height: 692,
    },
  ],
  contactInfo: {
    phoneNumbers: ['+905367856508'],
    emailAddress: ['can.inan@cogitotech.net'],
    //address: '121 King Street, Melbourne, Victoria 3000, Australia',
  },
}

Mock.onGet('/api/information').reply((config) => {
  const response = database.information
  return [200, response]
})

Mock.onGet('/api/steps').reply((config) => {
  const response = database.steps
  return [200, response]
})

Mock.onGet('/api/services').reply((config) => {
  const response = database.services
  return [200, response]
})

Mock.onGet('/api/techs').reply((config) => {
  const response = database.technologies
  return [200, response]
})

Mock.onGet('/api/portfolios').reply((config) => {
  const response = database.portfolios
  return [200, response]
})

Mock.onGet('/api/contactinfo').reply((config) => {
  const response = database.contactInfo
  return [200, response]
})
