// Socket types
export const OPEN_WEBSOCKET = 'OPEN_WEBSOCKET';
export const UPDATE_WEBSOCKET = 'UPDATE_WEBSOCKET';
export const PROVIDER_VISUAL_REVIEWED = 'PROVIDER_VISUAL_REVIEWED';
export const PRODUCT_REVIEWED = 'PRODUCT_REVIEWED';
export const SET_CHAT_LOADING = 'SET_CHAT_LOADING';
export const SUBSCRIBE_TO_CHATROOM = 'SUBSCRIBE_TO_CHATROOM';
export const RESET_CHAT_ROOMS = 'RESET_CHAT_ROOMS';
export const SET_SELECTED_ROOM_TYPE = 'SET_SELECTED_ROOM_TYPE';
export const SET_CHAT_ROOMS = 'SET_CHAT_ROOMS';
export const SET_SELECTED_ROOMID = 'SET_SELECTED_ROOMID';
export const SET_ROOM_CONVERSATION = 'SET_ROOM_CONVERSATION';
export const PUSH_NEW_MSG = 'PUSH_NEW_MSG';
export const MARK_MESSAGES_AS_READ = 'MARK_MESSAGES_AS_READ';
export const ROOM_STATUS_UPDATED = 'ROOM_STATUS_UPDATED';

// Auth reducer types
export const RETURN_TO_INITIAL = 'RETURN_TO_INITIAL';
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED';
export const SET_ADMIN_USER = 'SET_ADMIN_USER';
export const SET_SUPPORT_USER = 'SET_SUPPORT_USER';
export const SET_PROVIDER_USER = 'SET_PROVIDER_USER';
export const MARK_NOTIFICATIONS_READ = 'MARK_NOTIFICATIONS_READ';
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING';

// UI reducer types
export const SET_ERRORS = 'SET_ERRORS';
export const LOADING_UI = 'LOADING_UI';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const STOP_LOADING_UI = 'STOP_LOADING_UI';
export const SET_FLASH_MESSAGE = 'SET_FLASH_MESSAGE';

// Admin reducer types
export const SET_VEHICLES = 'SET_VEHICLES';
export const SET_CITIES = 'SET_CITIES';
export const SET_PROVIDERS = 'SET_PROVIDERS';
export const SET_PENDING_PRODUCT = 'SET_PENDING_PRODUCT';
export const SET_PENDING_PRODUCTS = 'SET_PENDING_PRODUCTS';
export const SET_PENDING_PROVIDER_VISUALS = 'SET_PENDING_PROVIDER_VISUALS';
export const MENU_ITEM_APPROVED = 'MENU_ITEM_APPROVED';
export const SET_COURIERS = 'SET_COURIERS';
export const SET_SUPPORTS = 'SET_SUPPORTS';
export const SET_PRODUCT_PACKAGES = 'SET_PRODUCT_PACKAGES';
export const SET_ADMIN_PRODUCTS = 'SET_ADMIN_PRODUCTS';

// Provider reducer types
export const SET_PROVIDER_STATUS = 'SET_PROVIDER_STATUS';
export const SET_PROVIDER_PROPS = 'SET_PROVIDER_PROPS';
export const SET_PROVIDER_DISCOUNTS = 'SET_PROVIDER_DISCOUNTS';
export const PROVIDER_CATEGORY_DELETED = 'PROVIDER_CATEGORY_DELETED';
//export const SET_MENUS = 'SET_MENUS';
export const SET_PROVIDER_CATEGORIES = 'SET_PROVIDER_CATEGORIES';
export const SET_ITEM_TAGS = 'SET_ITEM_TAGS';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const SET_SEARCH_PRODUCTS = 'SET_SEARCH_PRODUCTS';

export const SET_ORDER_TYPES = 'SET_ORDER_TYPES';
export const SET_SELECTED_ORDER_TYPE = 'SET_SELECTED_ORDER_TYPE';
export const SET_STATUS_FILTERS = 'SET_STATUS_FILTERS';
export const SET_ORDERS = 'SET_ORDERS';
export const SET_ORDER_STATUSES = 'SET_ORDER_STATUSES';

//general
export const LOADING_DATA = 'LOADING_DATA';

