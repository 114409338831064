import axiosInstance from '../redux/actions/axiosInstance'
import React, { Suspense, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import Layout from '../components/Layout'
//import Pagination from '../components/Pagination'
import PortfoliosView from '../components/PortfoliosView'
import Sectiontitle from '../components/Sectiontitle'
import Spinner from '../components/Spinner'

function Portfolio({ lightMode }) {
  const [portfolios, setPortfolios] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [portfoliosPerPage] = useState(9)

  useEffect(() => {
    let mounted = true
    axiosInstance.get('/api/portfolios').then((response) => {
      if (mounted) {
        setPortfolios(response.data)
      }
    })
    return () => (mounted = false)
  }, [])

  const indexOfLastPortfolios = currentPage * portfoliosPerPage
  const indexOfFirstPortfolios = indexOfLastPortfolios - portfoliosPerPage
  const currentPortfolios = portfolios.slice(
    indexOfFirstPortfolios,
    indexOfLastPortfolios,
  )

  const paginate = (e, pageNumber) => {
    e.preventDefault()
    setCurrentPage(pageNumber)
  }


  return (
    <Layout lightMode={lightMode}>
      <Helmet>
        <title>Our Work - Cogito Technology</title>
        <meta
          name="description"
          content="Cogito Technology Portfolios Page"
        />
      </Helmet>
      <Suspense fallback={<Spinner />}>
        <div className="mi-about mi-section mi-padding-top mi-padding-bottom">
          <div className="container">
            <Sectiontitle title="Our Work" />
            {<PortfoliosView portfolios={currentPortfolios} />}
            {/*
            !(portfolios.length > portfoliosPerPage) ? null : (
              <Pagination
                className="mt-50"
                itemsPerPage={portfoliosPerPage}
                totalItems={portfolios.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            )
            */}
          </div>
        </div>
      </Suspense>
    </Layout>
  )
}

export default Portfolio
