const BASE_IMG_URI = "https://pastasepeti.s3.eu-central-1.amazonaws.com/"


export const getRefImageUri = (provider_id, order_id, index) => {
    return BASE_IMG_URI + "provider/" + provider_id + "/orders/" + order_id + "/order_ref_image_" + index
}

export const getImageUri = (product_id, product_type, provider_id, image_type) => {
    if (product_type == "menu_item") {
        return BASE_IMG_URI + "provider/" + provider_id + "/products/" + product_id + "/" + image_type
    }
    if (product_type == "extra_item") {
        return BASE_IMG_URI + "extra_items/" + product_id + "/" + image_type
    }
    if (product_type == "extra_item_package") {
        return BASE_IMG_URI + "extra_item_packages/" + product_id + "/" + image_type
    }

}

export const getProviderVisualUri = (provider_id, visual_type) => {
    return BASE_IMG_URI + "provider/" + provider_id + "/visuals/" + visual_type
}

export const getCourierImage = (courier_id) => {
    return BASE_IMG_URI + "couriers/courier_" + courier_id
}

export const getSupportImage = (support_id) => {
    return BASE_IMG_URI + "supports/" + "support_" + support_id
}
