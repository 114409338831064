import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import authReducer from './reducers/authReducer'
import adminReducer from './reducers/adminReducer'
import socketReducer from './reducers/socketReducer'
import uiReducer from './reducers/uiReducer'

// Custom middlewares
import { socketMiddleware } from './middlewares'

const initialState = {}

const middleware = [thunk, socketMiddleware]

const reducers = combineReducers({
  auth: authReducer,
  admin: adminReducer,
  //socket: socketReducer,
  UI: uiReducer,
})

const composeEnhancers =
  (process.env.NODE_ENV !== 'production' &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose
const store = createStore(
  reducers,
  initialState,
  composeEnhancers(applyMiddleware(...middleware)),
)
/*
const store = createStore(
    reducers,
    initialState,
    compose(
        applyMiddleware(...middleware),
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
);
*/

export default store
