//import io from 'socket.io-client'
//import io from 'socket.io-client/dist/socket.io';
import { io } from "socket.io-client";

import { updateSocket } from '../actions';
import { SUBSCRIBE_TO_CHATROOM, OPEN_WEBSOCKET} from '../types';

const socketMiddleware = store => next => action => {
  //console.log('this is socket middle ware', action);

  if (action.type === SUBSCRIBE_TO_CHATROOM) {
    store.getState().socket.socket.emit('subscribe', action.payload);
  }

  //console.log("process.env:" + JSON.stringify(process.env))
  //console.log("socket uriii: " + "ws://" + process.env.REACT_APP_KONG_GATEWAY_URI.substring(7) + ":8000")
  //'ws://localhost:2525/'
  if (action.type === OPEN_WEBSOCKET) {
    console.log("OPENING WEB SOCKET FOR USER: " + action.payload.id);
    const socket = io("ws://", {
      //path: '/socket',
      query: ('id=' + action.payload.id),
      jsonp: false,
      reconnect: true,
      transports: ['websocket']
    })
    socket.on('connect', () => {
      store.dispatch(updateSocket(socket))
      console.log(`${socket.id} is connected`)
    })

    /*
    socket.on('product_created', (product) => {
      console.log("product_created from socket: " + product);
      //let product = JSON.parse(message.body);
      console.log("pathname: " + window.location.pathname)
      console.log(window.location.pathname === "/admin/pending_products")
      if (window.location.pathname === "/admin/pending_products") {
        store.dispatch(setPendingProducts(product));
      }
      store.dispatch(setFlashMessage({ title: "Yeni Ürün", msg: product.provider.title + " / " + product.name, type: "info" }));
    });
    */

    socket.on('connect_error', err => {
      console.error(err.message)
    })

    socket.on('disconnected', () => {
      console.log('socket disconnected')
    })
  }

  next(action)
}

export default socketMiddleware