import React from 'react';

// MUI Stuff
import { Grid, Modal, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';


const styles = {
    modalContainer: {
        width: "100%",
        height: "100%",
        backgroundColor: "rgb(53 31 86 / 50%)",
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8
        //backgroundColor: "white"
    },
    paper: {
        position: "relative",
        backgroundColor: "#fff",
        borderRadius: 8
        //?boxShadow: theme.shadows[5],
    },
    modalHeader: {
        width: '100%',
        height: "70px",
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8
    },
    modalHeaderText: {
        //padding: '0.5em'
    },
    body: {
        width: '100%',
        //maxHeight: '80%',
        //height: '85%',
        //overflow: "hidden",
        overflowY: "scroll",
        borderRadius: 8

    },
};

export default function SimpleModal(props) {
    const theme = useTheme();
    // getModalStyle is not a pure function, we roll the style only on the first render
    //const [modalStyle] = React.useState(getModalStyle);


    return (
        <div>
            <Modal
                open={props.open}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                //onClose={props.onClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Grid container justifyContent="center" alignItems="center" style={{ ...styles.modalContainer, ...props.modalContainerStyle }}>
                    {
                        !props.fullScreen
                            ?
                            <Grid item xs={props.xs} md={props.md} lg={props.lg} style={{ ...styles.paper, ...props.paperStyle }}>
                                {
                                    props.renderHeader
                                        ?
                                        <Grid container justifyContent="space-around" alignItems="center" style={{ ...styles.modalHeader, ...props.headerStyle }} sx={{ bgcolor: "primary.main" }}>
                                            {
                                                props.headerText
                                                    ?
                                                    <Grid item xs={10}>
                                                        <Typography variant="h6" style={{ ...styles.modalHeaderText, ...theme.styles.whiteTextShadow }}>{props.headerText}</Typography>
                                                    </Grid>
                                                    :
                                                    null
                                            }

                                            <Grid item xs={1} style={{ textAlign: "end" }}>
                                                <IconButton onClick={props.onCancelClick} style={styles.whiteTextShadow}>
                                                    <HighlightOffIcon fontSize="large" color="white" />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        :
                                        null
                                }

                                <Grid container style={{ ...styles.body, ...props.bodyStyle }}>
                                    {props.body}
                                </Grid>

                            </Grid>
                            :
                            props.fullScreenContent
                    }


                </Grid>
            </Modal>
        </div>
    );
}

SimpleModal.defaultProps = {
    renderHeader: true
}